.new-message,
.reply-message {
    margin-bottom: 10px;
}

.new-message .content-box:first-child,
.reply-message .content-box:first-child {
    border-bottom: 1px solid var(--secondary-lighter);
}

.new-message .buttons,
.reply-message .buttons {
    margin: 0 calc(var(--padding-base) * -1) !important;
    padding: var(--padding-base) var(--indent-base) 0;
    border-top: 1px solid var(--secondary-lighter);
}

.new-message hr,
.reply-message hr {
    height: 1px;
    margin: var(--padding-base) calc(var(--padding-base) * -1);
    border: none;
    color: var(--secondary-lighter);
    background-color: var(--secondary-lighter);
}

.new-message .new-message__message-panel .AccordionPanel-content-box .row {
    margin-bottom: 0;
}

.new-message .new-message__message-panel .form-group:last-of-type,
.reply-message .form-group:last-of-type {
    display: flex;
    flex-direction: column;
}

.new-message .new-message__buttons-block {
    padding: var(--padding-base);
}

.new-message .Title,
.reply-message .Title {
    margin: 0;
}

.reply-message .Content-box:first-of-type {
    padding-bottom: 0;
}

.new-message .TextInput-affix-wrapper .TextInput-prefix .Icon-search {
    margin-top: 5px;
}

.messages-page .messages-list .widget__empty {
    color: var(--primary-light);
    margin-top: var(--padding-base);
    text-align: center;
}

.messages-page .messages-list .widget__button {
    text-align: center;
}

.new-message .select .select__dropdown-options > div {
    max-height: 250px;
}
