.radio-group.radio-button-group {
    --radio-big-size: 24px;
    --radio-label-font: 16px;
    --radio-inner-size: 9px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
}

.radio-group.radio-button-group .radio-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin: 0 !important;
    width: 20%;
    text-align: center;
}

.radio-group.radio-button-group .radio-wrapper .radio-inner {
    width: var(--radio-big-size);
    height: var(--radio-big-size);
}

.radio-group.radio-button-group .radio-wrapper .radio-inner:after {
    width: var(--radio-inner-size);
    height: var(--radio-inner-size);
    background-size: var(--radio-inner-size);
    transition: 0s;
    transform: translate(-50%, -50%);
}

.radio-group.radio-button-group .radio-wrapper .radio-label {
    padding-left: 0;
    margin-bottom: var(--label-margin);
    font-size: var(--radio-label-font);
}
