.goal-review .stepper-content .Accordion .AccordionPanel .overview-panel {
    margin: 0 0 calc(var(--padding-base) * -1) 0;
}

.goal-review .stepper-content .Accordion .overview-panel .row {
    --row-margin-bottom: 0;
    width: 100%;
}

.goal-review .stepper-content .Accordion .overview-panel .overview-item {
    height: auto;
    margin-bottom: var(--padding-base);
}

.overview-item-center-md .overview-item:last-child {
    margin-top: calc(var(--margin-base) * -1);
}

.on-boarding .top-info-box {
    margin-bottom: var(--padding-base);
}

.on-boarding .goal-review:not(.summary) .stepper-content .risk-bandwidt-disclaimer .Info-box {
    margin-top: var(--padding-base);
    margin-bottom: 0;
}
