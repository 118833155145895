.Header__logo .logo-icon {
    margin: 0;
    max-width: 200px;
}

.Header__center .Header__logo div {
    display: flex;
    align-content: center;
    justify-content: center;
}

.logo-text {
    margin-left: 10px;
}

@media (max-width: 540px) {
    .Header__center .logo-text {
        display: none;
    }
}

.Header__left .Header__logo div {
    display: flex;
    align-content: center;
    justify-content: flex-start;
}
