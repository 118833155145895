.investment-allocation-mini {
    --pie-chart-colors-0: var(--primary-default);
    --pie-chart-colors-1: var(--accent-default);
    --pie-chart-colors-2: var(--accent-lighter);
    --pie-chart-colors-3: var(--primary-lighter);
    --pie-chart-colors-4: var(--accent-light);
    --pie-chart-colors-5: var(--accent-dark);
    --pie-chart-colors-6: var(--secondary-default);
    --pie-chart-colors-7: var(--positive-lighter);
    --pie-chart-colors-8: var(--positive-default);
    --pie-chart-colors-9: var(--positive-darker);
}

.investment-allocation-mini > .row {
    align-items: center;
}

.investment-allocation-mini .Tabs-extra__item {
    margin-left: var(--margin-base);
}

.investment-allocation-mini .chart-views {
    text-align: right;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .investment-allocation-mini .chart-views {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        margin: 20px 15px 0;
        border-top: 1px solid var(--secondary-lighter);
        border-bottom: 1px solid var(--secondary-lighter);
    }

    .investment-allocation-mini .Tabs-extra__item {
        margin-left: 0;
    }
}

.investment-allocation-mini .PieChart {
    justify-content: center;
}

.investment-allocation-mini .Highcharts-bar-chart {
    margin-top: var(--margin-base);
}

.investment-allocation-mini .Highcharts-bar-chart .highcharts-axis-labels > span {
    left: 0 !important;
    min-width: 107px;
}

.investment-allocation-mini .Highcharts-bar-chart .bar-chart__label {
    width: 125px;
    font-size: 12px;
    line-height: 14px;
}

.investment-allocation-mini .Highcharts-bar-chart .bar-chart__label .Form-control-label {
    font-size: 14px;
}

.investment-allocation-mini .PieChart .highcharts-pie {
    --pie-chart-size: 153px;

    justify-content: center;
}

.investment-allocation-mini .highcharts-pie svg {
    width: 153px;
    height: 153px;
}

.investment-allocation-mini .row + .Table-wrapper {
    margin-top: var(--margin-base);
}

.investment-allocation-mini .Table-wrapper {
    margin: 0 calc(var(--padding-base) * -1);
    width: auto;
}

.investment-allocation-mini .Table-wrapper .Table thead > tr {
    border-top: 1px solid var(--secondary-lighter);
}

.investment-allocation-mini .Table-wrapper tr th:first-child,
.investment-allocation-mini .Table-wrapper tr td:first-child {
    padding-left: var(--padding-base);
}

.investment-allocation-mini .Table-wrapper tr th:last-child {
    padding-right: var(--padding-base);
}

.investment-allocation-mini .Table-wrapper tr td:last-child {
    padding-right: var(--padding-base) !important;
}

.investment-allocation-mini .table .allocation-name .table-cell:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 14px;
    height: 14px;
    min-height: 14px;
    min-width: 14px;
    content: '';
    margin-right: 10px;
    border-radius: 50%;
}

.investment-allocation-mini .table .allocation-name .table-cell {
    display: flex;
    align-items: center;
}

.investment-allocation-mini .table tr:nth-child(1) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-0);
}

.investment-allocation-mini .table tr:nth-child(2) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-1);
}

.investment-allocation-mini .table tr:nth-child(3) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-2);
}

.investment-allocation-mini .table tr:nth-child(4) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-3);
}

.investment-allocation-mini .table tr:nth-child(5) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-4);
}

.investment-allocation-mini .table tr:nth-child(6) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-5);
}

.investment-allocation-mini .table tr:nth-child(7) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-6);
}

.investment-allocation-mini .table tr:nth-child(8) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-7);
}

.investment-allocation-mini .table tr:nth-child(9) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-8);
}

.investment-allocation-mini .table tr:nth-child(10) .allocation-name .table-cell:before {
    background-color: var(--pie-chart-colors-9);
}

.investment-allocation-mini .SelectButton .selected__values {
    width: 100%;
}
