.markets .Tabs-bar {
    margin-bottom: 0;
    margin-left: calc(var(--padding-base) * -1);
    margin-right: calc(var(--padding-base) * -1);
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
}

.markets .Tabs {
    margin-top: -18px;
}

.markets .info-box_accent,
.markets .Loader-wrapper {
    margin-bottom: 0;
    margin-top: var(--padding-base);
}

.markets .Table tbody > tr td.table-cell__subject {
    padding-top: 7px;
    padding-bottom: 7px;
}

.markets .Table-wrapper {
    width: auto;
    margin-left: calc(var(--padding-base) * -1);
    margin-right: calc(var(--padding-base) * -1);
}

.markets .Table-wrapper .Table.Table-mini {
    margin-left: var(--padding-base);
    margin-right: var(--padding-base);
    width: calc(100% - 2 * var(--padding-base));
}
