.modify-goal_content {
    margin-bottom: calc(var(--padding-base) * -1);
    padding: 0 var(--padding-base);
}

.modify-goal_content .Table .header-column {
    padding-right: 0;
    height: 55px;
}

.modify-goal_content .Table tr td {
    padding: 0;
    height: 56px;
}

.modify-goal_content .Table tr td:first-child {
    align-items: center;
}

.modify-goal_content .Table tr td .expand-icon {
    padding-right: 20px;
}

.modify-goal_content .Table tr:not(.row-level-0) td:first-child {
    overflow: initial;
}

.modify-goal_content .Table tr td.modify-goal__cell-new {
    padding: 0 var(--table-cell-horizontal-indents) 0 50px;
}

.modify-goal_content .Table tr td.modify-goal__cell-with-icons {
    padding-left: var(--table-cell-horizontal-indents);
}

.on-boarding_modify .buttons-block-row {
    margin-top: var(--padding-base);
}

.modify-goal_content .info-box_error {
    margin-bottom: var(--margin-base);
}
