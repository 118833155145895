.discard-changes,
.execute {
    min-width: 410px;
}

.create-proposal {
    min-width: 405px;
}

.change-strategy .Content-box > .row {
    margin-bottom: 0;
}

.rebalance .Content-box > .row {
    margin-bottom: 0;
}

.confirmation-page .content-box_title > .row {
    margin-bottom: 0;
}

.confirmation-page .Content-box > .row {
    margin-bottom: 0;
}

.confirmation-page .buttons-block {
    padding-top: 0;
    padding-bottom: calc(var(--padding-base) / 6);
}

.detailed-orders .buttons-block {
    padding-bottom: var(--padding-base);
}

.rebalance,
.confirmation-page {
    margin-top: calc(var(--indent-base) * 2);
}

.rebalance .content-wrapper,
.confirmation-page .content-wrapper {
    margin: 0 calc(var(--padding-base) * -1);
}

.rebalance .overview .info-box_error,
.confirmation-page .overview .info-box_error {
    margin-bottom: var(--padding-base);
}

.rebalance .overview-panel .row,
.confirmation-page .overview-panel .row {
    width: 100%;
}

.rebalance .overview-panel .overview-item:not(:last-child),
.confirmation-page .overview-panel .overview-item:not(:last-child) {
    padding-bottom: var(--padding-base);
}

.rebalance .strategy-overview,
.confirmation-page .strategy-overview {
    margin-bottom: var(--padding-base);
}

.confirmation + .buttons-block {
    padding-top: var(--padding-base);
}

.confirmation + .buttons-block .row {
    margin-bottom: 0;
}

.confirmation .positions .info-box_error {
    margin-top: 15px;
    margin-bottom: 15px;
}

.confirmation .positions .table tr td {
    height: 65px;
    padding-top: 0;
    padding-bottom: 0;
}

.confirmation .positions .table tr td.with-link {
    padding: 25px 0;
}

.confirmation .positions.AccordionPanel__active .AccordionPanel-header,
.confirmation .allocation.AccordionPanel__active .AccordionPanel-header {
    padding-bottom: 14px;
}

.confirmation .positions .AccordionPanel-content-box,
.confirmation .allocation .AccordionPanel-content-box {
    padding-bottom: 15px;
}

.confirmation .allocation .table-wrapper {
    margin-top: 0 !important;
}

.confirmation .allocation .highcharts-pie {
    width: 100% !important;
}

.confirmation .allocation .highcharts-pie .legend-item {
    height: 25% !important;
}

.confirmation .security-transactions.AccordionPanel__active .AccordionPanel-header {
    padding-bottom: 14px;
}

.confirmation .security-transactions .AccordionPanel-content-box {
    padding-bottom: 0;
}

.confirmation .security-transactions .info-box_error {
    margin-bottom: 20px;
}

.confirmation .security-transactions .widget__empty {
    margin: var(--padding-base) 0 20px !important;
}

.confirmation .security-transactions .Pagination {
    padding-bottom: var(--padding-base);
}

.confirmation .analysis .widget__empty,
.rebalance .analysis .widget__empty {
    margin-top: var(--margin-base);
}
