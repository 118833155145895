.ResizeHeight {
    position: relative;
    transition: height 0.1s ease 0s;
    user-select: unset;
}

.ResizeHeight .resize-content {
    overflow-y: hidden;
    height: auto;
}

.ResizeHeight .resize-bottom {
    z-index: 9;
    position: absolute;
    bottom: -21px;
    width: 100%;
}

.ResizeHeight .resize-bottom .resize-line {
    position: absolute;
    bottom: -10px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 4px 0;
    user-select: none;
    background-color: var(--white);
}

.ResizeHeight .resize-bottom .resize-line .resize-button {
    padding: 5px;
}

.ResizeHeight.height-change-start {
    transition: none;
    user-select: none;
}

.ResizeHeight .resize-icon {
    height: 6px;
    width: 16.5px;
    cursor: row-resize;
    border-top: 2px solid;
    border-bottom: 2px solid;
}

.dashboard .resize-bottom {
    background: var(--white);
}

.dashboard .tasks .resize-content,
.dashboard .messages .resize-content,
.dashboard .notes .resize-content {
    margin: 0 calc(var(--padding-base) * -1);
}
