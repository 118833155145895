.table-cell_checkboxed {
    display: flex;
    align-items: center;
}

.table-cell_checkboxed .checkbox {
    margin-right: 15px;
}

.table-cell_checkboxed .checkbox-inner {
    width: 24px;
    height: 24px;
}

.table-cell_checkboxed .checkbox-checked .checkbox-inner:after {
    width: 12px;
    height: 12px;
}
