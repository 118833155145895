.quick-access {
    --avatar-size-sm: 28px;
    --avatar-size: 36px;
    --quick-access-link-marker-size: 6px;

    display: flex;
    justify-content: stretch;
    align-items: center;
    color: var(--header-navigation-color);
}

.quick-access:hover {
    color: var(--header-navigation-active);
}

.quick-access__active {
    color: var(--header-navigation-active);
}

.quick-access__plus-menu {
    --quick-access-max-width: 290px;
    --quick-access-max-height: 300px;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
}

.QuickAccessMenu {
    --quick-access-plus-menu-min-width: 150px;
}

.QuickAccessMenu .Menu {
    min-width: var(--quick-access-plus-menu-min-width);
}

.QuickAccessMenu.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
    left: 124px;
}

.plus-menu-icon-wrapper {
    display: flex;
    margin-right: var(--padding-base);
    cursor: pointer;
    align-items: center;
}

.plus-menu-icon-wrapper > span {
    text-align: center;
}

@media only screen and (max-width: 480px) {
    .quick-access__plus-menu {
        max-width: 100%;
    }
}
