.review-payment .review-payment-content {
    margin: 0 auto;
    max-width: 698px;
    width: 100%;
}

.review-payment.account-review .payment-details {
    padding-top: var(--padding-base);
    margin-bottom: calc(var(--padding-base) * -1);
}

.review-payment + .buttons-block-row {
    border-top: 1px solid var(--secondary-lighter);
    margin-bottom: calc(var(--margin-base) * 2);
}

.review-payment .payments-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-lighter);
    padding: 0 var(--padding-base);
    margin: 0 calc(var(--padding-base) * -1);
}

.review-payment-content {
    margin-top: var(--padding-base);
}

.review-payment .review-payment-content .row {
    margin-bottom: 0;
}

.review-payment .payment-details-title {
    margin-bottom: calc(var(--margin-base) / -2);
}

.review-payment .review-payment-details .group-title {
    margin-top: calc(var(--margin-base));
}

.review-payment .review-payment-details .group-title .Title {
    margin-bottom: calc(var(--margin-base) / 2);
}

.review-payment .review-payment-details .Title {
    font-size: 1.4rem;
}

.review-payment .review-payment-info {
    color: var(--primary-default);
    font-size: 1.4rem;
    line-height: 1.57142857;
    margin-bottom: 0;
}

.review-payment .review-payment-description {
    margin-top: calc(var(--margin-base) + 5px);
    padding-bottom: calc(var(--margin-base) / 2);
    margin-bottom: var(--margin-base);
    border-bottom: 1px solid var(--secondary-lighter);
}
