.draggable {
    cursor: move;
    cursor: grab;
}

.Content-box > .draggable {
    margin: calc(var(--padding-base) * -1) calc(var(--padding-base) * -1) 0 calc(var(--padding-base) * -1);
    padding: var(--padding-base);
}

.Content-box > .draggable h1,
.Content-box > .draggable h2,
.Content-box > .draggable h3,
.Content-box > .draggable h4 {
    display: inline-block;
    margin-bottom: 0;
    cursor: auto;
}

.content-box_title > .draggable:not(.draggable-without_border) {
    border-bottom: 1px solid var(--secondary-lighter);
}

.Content-box > .draggable .Tabs .Loader-wrapper,
.content-box_title > .draggable:not(.draggable-without_border) + .Loader-wrapper {
    margin-top: calc(var(--margin-base) * 2.5);
    margin-bottom: var(--margin-base);
}
