.new-portfolio .Content-box {
    margin-bottom: var(--padding-base);
}

.new-portfolio  .new-portfolio-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-lighter);
    padding: 0 var(--padding-base);
    margin: 0 calc(var(--padding-base) * -1);
}

.new-portfolio .new-portfolio-content {
    margin: var(--padding-base) auto 0;
    max-width: 698px;
    width: 100%;
}

.new-portfolio .information {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--secondary-lighter);
    margin-bottom: 20px;
}

.new-portfolio .information .Title {
    margin-top: 10px;
    margin-bottom: calc(var(--margin-base) / 2);
}

.new-portfolio .profile {
    border-bottom: 1px solid var(--secondary-lighter);
}

.new-portfolio .profile .Title {
    margin-bottom: calc(var(--margin-base) / 2);
}

.new-portfolio .create-form {
    margin-top: -10px;
}

.new-portfolio .buttons-block-row {
    border-top: 1px solid var(--secondary-lighter);
    margin-left: calc(-1 * var(--padding-base));
    margin-right: calc(-1 * var(--padding-base));
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
}

.new-portfolio .strategy-loader {
    position: relative;
}

.new-portfolio .strategy-loader .Loader-wrapper {
    position: absolute;
    top: -27px;
    margin: 0!important;
}

.new-portfolio .redo-risk-profile {
    margin-bottom: 20px;
    height: 20px;
    font-size: 12px;
}

.new-portfolio .amount .select .select__dropdown-options > div {
    max-height: 150px;
}
