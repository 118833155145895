.ThemeToggle h4 {
    color: var(--primary-default);
    margin-bottom: 0;
    font-weight: bold;
}

.ThemeToggle {
    display: flex;
    align-items: center;
    padding: var(--indent-base) var(--margin-base) 0;
    border-top: var(--border-base);
    justify-content: space-between;
    margin: 0 calc(var(--margin-base) * -1);
    margin-bottom: -4px;
}
