.registration .formio-wrapper .alert {
    display: none;
}

.registration .formio-form {
    display: grid;
    grid-template-columns: minmax(auto, 50%) minmax(auto, 50%);
}

.registration .formio-form > .formio-component {
    margin: 0 var(--margin-base) var(--margin-base);
}

.registration .formio-form > .formio-component.formio-component-personalInformation\.salutation,
.registration .formio-form > .formio-component.formio-component-taxSituation\.isUsPerson,
.registration .formio-form > .formio-component.formio-component-politicalExposure\.isPoliticallyExposedPerson {
    grid-column-start: 1;
    grid-column-end: 3;
    width: calc(50% - 2 * var(--margin-base));
}

@media only screen and (max-width: 768px) {
    .registration .formio-form > .formio-component.formio-component-politicalExposure\.isPoliticallyExposedPerson .checkbox-wrapper span {
        width: 272px;
    }
}
