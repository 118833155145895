@media only screen and (min-width: 1040px) {
    #root .PieChart .highcharts-pie:not(:only-child) {
        width: 25%;
    }
}

@media (min-width: 768px) and (max-width: 1040px) {
    #root .PieChart {
        justify-content: left;
        align-items: flex-start;
    }
}
