.on-boarding .contract-result {
    min-height: calc(100vh - 515px);
}

.on-boarding .contract .description-info {
    margin-bottom: var(--margin-base);
}

.on-boarding .contract {
    margin-bottom: calc(var(--padding-base) - 5px);
}

.on-boarding .contract .list-title {
    margin-bottom: var(--indent-base);
    font-weight: 500;
}

.on-boarding .contract .list {
    margin-left: calc((var(--padding-base) * -1) + 6px);
}
