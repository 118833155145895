.new-portfolio_instrument .overview-panel .row {
    --row-margin-bottom: 0;
}

.new-portfolio_instrument .Content-box:not(.PageHeader) {
    padding: 0;
}

.new-portfolio_instrument .Accordion {
    border-top: none;
}

.new-portfolio_instrument .AccordionPanel-content-box .row {
    --row-margin-bottom: 0;
}

.new-portfolio_instrument .Content-box > .buttons-block-row {
    border-top: none;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: var(--content-box-p);
}
