.single-portfolio-edit {
    --indent-base: 15px;

    margin-top: calc(var(--indent-base) * 2);
}

.single-portfolio-edit h1 {
    margin-bottom: calc(var(--indent-base) * 2);
}

.single-portfolio-edit h1 + button {
    margin-top: -55px;
}

.single-portfolio-edit .table tr td {
    height: 65px;
    padding-top: 0;
    padding-bottom: 0;
}

.single-portfolio-edit .table tr th.column-fixed {
    text-align: center;
}

.single-portfolio-edit .table tr td.cell-fixed {
    background-color: transparent;
}

.single-portfolio-edit .content-wrapper {
    margin: 0 calc(var(--padding-base) * -1);
}

.single-portfolio-edit .buttons-block {
    margin-top: calc(var(--indent-base) * 2);
}

.single-portfolio-edit .buttons-block .row {
    margin-bottom: 0;
}

.single-portfolio-edit__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    margin-top: calc(var(--indent-base) * 2);
}

.single-portfolio-edit__buttons .button + .button {
    margin-left: var(--margin-base);
}

.single-portfolio-edit__header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
}

@media only screen and (min-width: 576px) {
    .single-portfolio-edit__header {
        align-items: center;
        flex-direction: initial;
    }
}

.single-portfolio-edit__header h3 {
    margin: 0;
}

.single-portfolio-edit .Accordion {
    width: auto;
    margin: 0 calc(var(--indent-base) * 2);
}

.single-portfolio-edit .AccordionPanel-content-box {
    position: relative;
    bottom: -1px;
    padding-bottom: 0 !important;
}

.single-portfolio-edit .table-wrapper {
    margin-bottom: 0;
    padding: 0 var(--padding-base);
    border-top: 1px solid var(--secondary-lighter);
    border-bottom: 1px solid var(--secondary-lighter);
}

.single-portfolio-edit .table-wrapper tr td.with-link {
    padding: 25px 0;
}

.single-portfolio-edit .table-row:last-child .table-cell {
    border: 0;
}

.single-portfolio-edit .table-cell-header {
    padding-top: 0;
}

.single-portfolio-edit .ant-input {
    padding-right: var(--input-horizontal-padding) !important;
}

.single-portfolio-edit .form-group {
    margin-bottom: 0;
}

.single-portfolio-edit .Info-box {
    margin: var(--padding-base) var(--padding-base) 0;
}

.single-portfolio-edit .analysis .widget__empty {
    margin-top: var(--margin-base);
}
