.Surfly {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.Surfly .frame {
    flex-grow: 1;
    height: 900px;
    margin: 0;
    padding: 0;
    border: none;
}
