.dashboard .tasks .Table-content,
.dashboard .messages .Table-content,
.dashboard .notes .Table-content {
    padding: 0 var(--padding-base);
}

.dashboard .Table.Table-mini tr.row-level-0 td:last-child {
    padding-right: 0 !important;
}

.dashboard .Table.Table-mini tr.row-level-0 td:first-child {
    padding-left: 0 !important;
}

.dashboard .resize-widget {
    overflow-y: hidden;
    border: var(--border-width-base) var(--white) solid;
}

.dashboard .resize-widget.limited-size {
    border: var(--border-width-base) var(--negative-light) dashed;
}

.dashboard .ResizeHeight .Pagination {
    display: none;
}

.dashboard .overview {
    padding: var(--padding-base);
    background-color: var(--white);
}

.dashboard .overview__header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.dashboard .overview__header .form-group {
    margin-bottom: 0;
}

.dashboard .overview-item {
    padding-bottom: 0;
}

@media (max-width: 768px) {
    .dashboard .news .news-item {
        --image-max-height: 300px;
    }

    .dashboard .news .news-item--image {
        max-height: var(--image-max-height);
        object-fit: cover;
    }

    .dashboard .news .news-item--image.Skeleton {
        max-height: var(--image-max-height);
    }
}

@media (max-width: 576px) {
    .dashboard .news .news-item {
        --image-max-height: 200px;
    }

    .dashboard .news .news-item--image {
        height: var(--image-max-height);
        object-fit: cover;
    }

    .dashboard .news .news-item--image.Skeleton {
        max-height: var(--image-max-height);
    }
}

.dashboard .total-client-assets .select {
    float: right;
}

.dashboard .notes .table-cell__subject,
.dashboard .documents .table-cell__subject,
.dashboard .messages .table-cell__subject,
.dashboard .notes .table-cell__document,
.dashboard .documents .table-cell__document,
.dashboard .messages .table-cell__document {
    max-width: 120px;
    width: 120px;
}

.dashboard .notes .table-cell__date,
.dashboard .documents .table-cell__date,
.dashboard .messages .table-cell__date {
    max-width: 86px;
    width: 86px;
}

.dashboard .messages {
    margin-top: 0;
    margin-bottom: 0;
}

.dashboard .messages__button {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    padding: var(--margin-base) var(--padding-base) 25px;
    margin: 0 calc(var(--padding-base) * -1);
    background: var(--white);
    border-top: 1px solid var(--secondary-lighter);
}

.dashboard .messages .ResizeHeight .resize-bottom {
    bottom: -25px;
}

.dashboard .messages .ResizeHeight .resize-bottom .resize-line {
    bottom: -5px;
}

.dashboard .widget__empty {
    padding: var(--margin-base) 0 10px;
    color: var(--primary-light);
    font-size: 1.4rem;
    line-height: 1.14285714;
    text-align: center;
}

.dashboard .widget__button {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.dashboard .table-cell {
    vertical-align: middle;
    font-size: 1.4rem;
    line-height: 1.14285714;
}

.dashboard .table-cell_linked {
    color: var(--primary-default);
    text-decoration: underline;
}

.dashboard .highcharts-pie.highcharts-pie_single-allocation .legend-item {
    margin-left: calc(var(--indent-base) / 3);
}

.dashboard .cell-icon {
    color: var(--primary-light);
}

.dashboard .cell-icon_header {
    color: var(--primary-default);
}
