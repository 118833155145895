.list-preview {
    margin-bottom: 10px;
}

.list-preview .search-wrapper {
    margin: 0 auto var(--padding-base);
    width: 75%;
}

.list-preview .widget__empty {
    border: 0;
}

.list-preview .Tabs .Tabs-bar {
    margin-bottom: 0;
    padding-left: var(--padding-base);
}

.list-preview .Tabs .TabPane .info-box_accent {
    margin-top: 30px;
}

.list-preview__content {
    margin: calc(var(--padding-base) * -1) calc(var(--padding-base) * -1) !important;
    padding: 0 var(--indent-base) 0 0;
    border-top: 1px solid var(--secondary-lighter);
}

.list-preview .preview-container {
    position: relative;
    padding: 0;
    height: 100%;
    border-left: 1px solid var(--secondary-lighter);
    margin-right: calc(var(--indent-base) * -1);
}

.list-preview__list {
    padding: 0 0 var(--padding-base);
    margin-right: -1px;
}

.list-preview .list-preview__list .Table-wrapper .Pagination {
    border: none;
}

.list-preview .list-preview__list .Table tbody>tr.row-level-0 {
    border-bottom: 1px solid var(--secondary-lighter);
}
