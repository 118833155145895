.client-page .Info-box {
    margin-top: var(--margin-base);
}

.client-page .TextInput-group-wrapper {
    --text-input-height-base: 50px;
    --text-input-affix-prefix-icon-search-mt: var(--text-input-height-base);
}

.client-page .form-group {
    background: #fff;
    margin: var(--padding-base) auto;
    width: 75%;
}

.client-page .title {
    margin-bottom: 0;
}

.client-page .Table th:first-child,
.client-page .Table td:first-child {
    padding-left: calc(var(--indent-base) * 2);
}

.client-page .Table th:last-child,
.client-page .Table td:last-child {
    padding-right: calc(var(--indent-base) * 2);
}

.client-page .Table-wrapper .column-fixed,
.client-page .Table-wrapper .cell-fixed {
    background: var(--table-bg);
}

.client-page .Pagination {
    padding-top: var(--padding-base);
}

.client-page .col-buttons button {
    margin: var(--margin-base) var(--margin-base) 0 0;
}

@media only screen and (max-width: 576px) {
    .client-page .col-buttons button {
        margin: 0 0 0 var(--margin-base);
    }
}

.client-page .table-wrapper {
    width: auto;
    min-height: 615px;
    margin: 0 calc(calc(var(--indent-base) * 2) * -1);
    border-top: 1px solid var(--secondary-lighter);
}

.client-page .table-row:last-child .table-cell {
    border-bottom: 0;
}

.client-page .table-cell_icon--header {
    color: inherit;
}

.client-page .button-right {
    text-align: right;
}

.client-page .Tabs-bar {
    border-bottom: none;
    margin-bottom: 0;
}

.client-page .new-prospect-button {
    display: flex;
    justify-content: flex-end;
}
