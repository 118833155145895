.client-details .loading-overlap {
    height: 100%;
}

.client-details .formio-wrapper {
    scroll-margin-top: calc(var(--padding-base) + 90px);
}

.client-details .formio-component .editgrid-actions {
    display: flex;
}

.client-details .formio-component .editgrid-actions .btn:not(:last-child) {
    margin-right: var(--margin-base);
}

.client-details .details-section {
    margin-top: var(--padding-base);
    border-bottom: 1px solid var(--secondary-lighter);
}

.client-details .details-section .col-7 {
    padding-right: 0;
}

.client-details .col-buttons {
    padding-top: 5px;
}

.client-details .col-buttons button {
    margin-left: var(--margin-base);
}

.client-details .has-feedback .form-control {
    padding-right: 0;
}

.client-details .formio-component .table {
    width: 100%;
    border-spacing: 0;
}

/* Hide default submit */
.client-details .formio-component.formio-component-button.formio-component-submit {
    display: none;
}

/* Accordion component */
.client-details .formio-form > .formio-component-panel {
    border-bottom: var(--border-base);
    display: none;
}

.client-details .formio-form > .formio-component-panel.active {
    display: block;
}

.client-details .custom-components > .custom-component {
    display: none;
}

.client-details .custom-components > .custom-component.active {
    display: block;
}

.client-details .details-form-io .formio-form {
    min-height: 0;
}

.client-details .details-form-io {
    margin-top: calc(var(--margin-base) * -1);
}
