/* Rude hack. Probably it should be removed after https://additiv.atlassian.net/browse/AT-1505 fix */
.interaction-page .preview-content-metadata .additiv-picker {
    width: 100%;
}

.interaction-page .comment .interaction-field p {
    max-height: 220px;
}

.interaction-page .list-preview .Tabs .Tabs-bar {
    padding-left: 0;
    border-top: 1px solid var(--secondary-lighter);
}

.interaction-page .log-entry-bottons {
    width: 100%;
}

.interaction-page button {
    border-radius: 3px;
}

.interaction-page .log-entries .Table-wrapper {
    max-height: 465px;
}

.interaction-page .add-entry {
    margin-top: 10px;
    margin-bottom: 10px;
}

.interaction-page .preview-content table td:first-child {
    max-width: 90px;
}

.interaction-page .list-preview__content .Title {
    margin-bottom: 30px;
}

.interaction-page .list-preview__content .preview-buttons {
    margin-top: 0;
}

.interaction-page .list-preview__content .preview-content .preview-content-metadata {
    border-top: none;
    margin-bottom: -10px;
}

.interaction-page .interactions-list .widget__empty {
    color: var(--primary-light);
    margin-top: var(--padding-base);
    text-align: center;
}

.interaction-page .interactions-list .widget__button {
    text-align: center;
}

.interaction-page .interactions-list .Tabs-nav {
    max-width: 100%;
    padding: 0 var(--padding-base);
}

.interaction-page .interactions-list .Tabs .Tabs-bar {
    border-top: none;
}
