.text-right {
    text-align: right !important;
}

@media (min-width: 576px) {
    .text-sm-right {
        text-align: right !important;
    }
}

.with-mb {
    margin-bottom: var(--padding-base);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: var(--secondary-lighter);
}

#root .button-group:not(.button-group--horiz) button + button {
    margin-left: var(--margin-base);
}

a:not(.Navigation__link):hover {
    color: var(--navigation-link-color-hover);
}

.Loader-wrapper {
    margin-top: calc(var(--margin-base) * 2.5);
    margin-bottom: calc(var(--margin-base) * 2.5);
}

.button .Loader-wrapper {
    margin: 0 auto;
}

.Title.underlined {
    padding-bottom: var(--padding-base);
    margin-bottom: 0;
    margin-left: calc(var(--padding-base) * -1);
    margin-right: calc(var(--padding-base) * -1);
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
    border-bottom: var(--border-width-base) solid var(--secondary-lighter);
}

.Title.underlined.with-mb {
    margin-bottom: var(--padding-base);
}

.table-without-last-row-padding .Table:not(.hasPagination) tbody tr:last-child td {
    padding-bottom: 0;
}

.table-without-head-row-padding .Table thead tr th {
    padding-top: 0;
}

.mt-5 {
    margin-top: 5px;
}

.mt-20 {
    margin-top: 20px;
}
