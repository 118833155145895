.PhoneNumber {
    --selectWidth: 90px;
}

.PhoneNumber .PhoneNumber-container {
    display: flex;
}

.PhoneNumber .PhoneNumber-container .PhoneNumber-input {
    width: 100%;
}

.PhoneInput-disabled input {
    pointer-events: none;
}

.PhoneNumber .PhoneNumber-container .PhoneNumber-input .TextInput {
    width: calc(100% - var(--selectWidth) - 10px);
}

.PhoneNumber .PhoneNumber-container .PhoneNumber-input .TextInput-affix-wrapper .TextInput-prefix {
    left: -1px;
}

.PhoneNumber .PhoneNumber-container .PhoneNumber-input .TextInput-affix-wrapper .TextInput-prefix :not(.anticon) {
    line-height: calc(16 / 14);
}

.PhoneNumber .PhoneNumber-container .TextInput-affix-wrapper .TextInput-prefix + input.TextInput {
    margin-left: calc(var(--selectWidth) + 10px);
    padding-left: 10px;
}

.PhoneNumber .PhoneNumber-container .select,
.PhoneNumber .PhoneNumber-container .PhoneNumber-select {
    width: var(--selectWidth);
}
