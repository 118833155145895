.table-cell_ticket {
    /* stylelint-disable */
    --ticket-purple: #870076;
    /* stylelint-enable */

    padding: 2px 0 2px 10px;
    border-left: 6px solid var(--ticket-purple);
    font-size: 1.4rem;
    line-height: 1.6rem;
}

.table-cell_ticket p {
    margin: 0;
}

.table-cell_ticket b {
    font-size: 1.6rem;
    line-height: 1.9rem;
}

.table-cell_ticket div.gray-text {
    margin-top: 5px;
    color: var(--primary-light);
    line-height: 2.2rem;
}

.table-cell__contact {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    margin-right: 5px;
    cursor: pointer;
}

.table-cell_ticket.table-cell_ticket__color-green {
    border-left: 6px solid var(--event-green);
}

.table-cell_ticket.table-cell_ticket__color-turquoise {
    border-left: 6px solid var(--event-turquoise);
}

.table-cell_ticket.table-cell_ticket__color-purple {
    border-left: 6px solid var(--event-purple);
}

.table-cell_ticket.table-cell_ticket__color-blue {
    border-left: 6px solid var(--event-primary);
}
