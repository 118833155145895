.advisory-dashboard {
    padding-top: var(--padding-base);
}

.advisory-dashboard .row {
    margin-bottom: 0;
}

.advisory-dashboard .Content-box {
    margin-bottom: var(--padding-base);
}

.advisory-dashboard .widget-wrapper.dragging .Content-box {
    box-shadow: 0 0 10px 0 var(--box-shadow);
}

.advisory-dashboard .PieChart .highcharts-pie.highcharts-pie_single-allocation {
    min-width: 50%;
}

.advisory-dashboard .PieChart .highcharts-pie.highcharts-pie_single-allocation .legend-name {
    max-width: 200px;
}

.advisory-dashboard .information .Loader-wrapper,
.advisory-dashboard .documents .Loader-wrapper,
.advisory-dashboard .information .info-box_error,
.advisory-dashboard .documents .info-box_error {
    margin-bottom: var(--indent-base);
}

.advisory-dashboard .documents .Loader-wrapper,
.advisory-dashboard .messages .Loader-wrapper,
.advisory-dashboard .notes .Loader-wrapper {
    margin-top: var(--padding-base);
}

.advisory-dashboard .news .Loader-wrapper {
    margin-bottom: var(--margin-base);
}

.advisory-dashboard .documents {
    padding-bottom: var(--indent-base);
}

.advisory-dashboard .news {
    margin-top: 0;
}

.advisory-dashboard .highcharts-pie .legend-value {
    white-space: nowrap;
}

.advisory-dashboard .LineChart .highcharts-tooltip > span {
    padding: 7px !important;
    white-space: nowrap !important;
}

.advisory-dashboard .table-body-scroll-250 {
    max-height: 250px;
}

.advisory-dashboard .info-box div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.advisory-dashboard .info-box div > span {
    width: 65%;
}

@media only screen and (max-width: 576px) {
    .advisory-dashboard .info-box div > span {
        width: 100%;
    }
}

.advisory-dashboard .overview-panel .Info-box {
    width: 100%;
}

.advisory-dashboard .table-cell {
    vertical-align: middle;
    font-size: 1.4rem;
    line-height: 1.14285714;
}

.advisory-dashboard .table-cell_linked {
    color: var(--primary-default);
    text-decoration: underline;
}

.advisory-dashboard .table-cell__percent_negative {
    color: var(--negative-default);
}

.advisory-dashboard .calendar-wrap .calendar {
    margin-top: -25px;
}

.advisory-dashboard .calendar-wrap .buttons-block {
    margin-top: var(--margin-base);
}

.advisory-dashboard .calendar-wrap.loading,
.advisory-dashboard .calendar-wrap.loading .calendar {
    position: relative;
}

.advisory-dashboard .calendar-wrap.loading .calendar:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: '';
    /* stylelint-disable */
    background-color: rgba(var(--white-rgb), 0.5);
    /* stylelint-enable */
}

.advisory-dashboard .calendar-wrap.loading .calendar-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
}

.advisory-dashboard .calendar-wrap.loading .calendar-loader .Loader-wrapper {
    margin: 0;
}

.advisory-dashboard .messages .ResizeHeight {
    margin-bottom: -35px;
}

.advisory-dashboard .messages .ResizeHeight .resize-bottom {
    bottom: calc(var(--margin-base) / 2);
}

.advisory-dashboard .investment-allocation-full .draggable {
    margin-bottom: calc(var(--margin-base) * -1);
}

.advisory-dashboard .investment-allocation-full .Table {
    margin-top: calc(var(--margin-base) / -2);
}
