.on-boarding {
    margin: var(--padding-base) 0;
}

.on-boarding .Steps-container {
    margin: 0 0 var(--padding-base) 0;
    max-width: 100%;
    width: auto;
}

.on-boarding .Steps-item {
    flex: 1 1 0;
}

.on-boarding .Steps-item-icon {
    margin-left: calc(50% - 16px);
}

.on-boarding .Steps-item-tail {
    margin-left: 50%;
}

.on-boarding .Steps-item-content {
    width: auto;
    max-width: 100%;
    padding: 0 5px;
}

.on-boarding .on-boarding_header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-lighter);
    padding: 0 var(--padding-base);
    margin: 0 calc(var(--padding-base) * -1);
}

.on-boarding .stepper-content {
    margin: var(--padding-base) auto 0;
    max-width: 880px;
    width: 100%;
}

.on-boarding .stepper-content-full {
    max-width: 100%;
}

.on-boarding .stepper-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    border-top: 1px solid var(--secondary-lighter);
}

.on-boarding .stepper-buttons .button+.button {
    margin-left: var(--padding-base);
}

.on-boarding .stepper-buttons .button-primary {
    margin-left: auto;
}

.on-boarding .stepper-buttons-right {
    justify-content: flex-end;
}

.on-boarding .buttons-block {
    display: flex;
    justify-content: flex-end;
}

.on-boarding .goal-review .stepper-content {
    margin: 0 calc(-1 * var(--padding-base)) !important;
    width: auto;
    max-width: initial;
}

.on-boarding .goal-review .stepper-content.single-portfolio .Accordion {
    border-top: none;
}

.on-boarding .goal-review:not(.summary) .stepper-content .Info-box {
    margin-left: var(--padding-base);
    margin-right: var(--padding-base);
}

.on-boarding .goal-review.summary .stepper-content .Info-box {
    margin-bottom: calc(var(--padding-base) / 2);
}

.on-boarding .goal-review .stepper-content .overview {
    margin: var(--padding-base) var(--padding-base) 0;
}

.on-boarding .goal-review .stepper-content .content-wrapper {
    margin: 0;
}

.on-boarding .stepper-content .Accordion>.AccordionPanel:last-child {
    border-bottom: none;
}

.on-boarding .highcharts-pie .legend-item {
    width: 100%;
}

.on-boarding .documents .table-cell_linked {
    text-decoration: underline;
    cursor: pointer;
}

.on-boarding .PageVariant.main {
    height: auto !important;
}

.on-boarding .PageVariant.main .Info-box {
    margin-top: var(--margin-base);
}

.on-boarding .PageVariant.wrapper {
    padding-top: 0;
}

.on-boarding .PageVariant.wrapper .main {
    --page-variant-main-mw: 415px;
}

.on-boarding .on-boarding_review,
.on-boarding .on-boarding_add-position {
    margin-bottom: calc(var(--padding-base) * -1);
}

.on-boarding .on-boarding_add-position .table-wrapper {
    border-top: 1px solid var(--secondary-lighter);
    margin-bottom: var(--padding-base);
}

.on-boarding .on-boarding_position-details {
    padding-bottom: 0;
}

.on-boarding .on-boarding_position-details .stepper-content .overview-panel {
    margin: initial;
}

.on-boarding .on-boarding_position-details .Accordion {
    border-top: none;
}

.on-boarding .on-boarding_position-details .performance .AccordionPanel-content.AccordionPanel-content__open {
    z-index: 2;
    overflow: visible;
}

.on-boarding .performance .widget__empty {
    text-align: center;
    margin-top: calc(var(--padding-base));
    margin-bottom: 0;
}

.on-boarding .performance .row {
    margin-bottom: 0;
}

.on-boarding > .Steps-container + .Loader-wrapper {
    min-height: calc(100vh - 676px);
}

.on-boarding > .Loader-wrapper {
    min-height: calc(100vh - 520px);
}

.on-boarding .goal-review .stepper-content .change-strategy-overview {
    margin-bottom: var(--margin-base);
}

.on-boarding .goal-review .stepper-content .overview-strategy .overview-item {
    margin-top: 0;
}

.on-boarding .goal-reached .Info-box {
    border-color: var(--infobox-border-color-theme);
}
