.risk-profile-review .review-section {
    margin-bottom: 30px;
    border-bottom: 1px solid var(--secondary-lighter);
}

.risk-profile-review .review-section .row {
    margin-bottom: 20px;
}

.risk-profile-review .review-section h3 {
    margin-bottom: 8px;
}

.risk-profile-review .review-section a {
    color: var(--primary-light);
    text-decoration: underline;
}

.risk-profile-review .review-section a:hover {
    color: var(--primary-dark);
}

.risk-profile-review .review-section .result-radio {
    text-align: center;
}

.risk-profile-review .review-section .result-radio .result-radio__label {
    display: inline-block;
    margin: 10px 0;
    font-size: 1.6rem;
    line-height: 1.375;
}
