.overview .button-download {
    text-align: right;
}

@media only screen and (max-width: 768px) {
    .overview .button-download {
        text-align: left;
    }
}

.overview-panel {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.overview-panel:not(:last-child) {
    padding-bottom: var(--padding-base);
}

.overview-item__currency_large {
    font-size: 20px !important;
}

.overview-item {
    display: flex;
    justify-content: normal;
    align-items: center;
    color: var(--primary-default);
}

.overview-item__title {
    font-size: 20px;
    line-height: 1.1875;
    font-weight: bold;
}

.overview-item__value {
    font-size: 3.2rem;
    line-height: 1.125;
}

.overview-item__value_large {
    font-size: 20px;
}

.overview-item__value_small {
    font-size: 1.6rem;
    color: var(--primary-light);
    line-height: 2.25;
}

.overview-item__currency {
    color: var(--primary-default);
    font-size: 1.6rem;
    line-height: 1.1875;
}

.overview-item__currency_align_left {
    margin-right: 5px;
}

.overview-item__currency_align_right {
    margin-left: 5px;
}

.overview-item__value_positive {
    color: var(--positive-default);
}

.overview-item__value_negative {
    color: var(--negative-default);
}

.overview-item-mini .overview-item__value_small {
    color: var(--primary-default);
}

.overview-item-mini .overview-item__value_positive {
    color: var(--positive-dark);
}

.overview-item-mini .overview-item__text {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.overview-client-mini__button__container {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    padding: var(--margin-base) var(--padding-base) 0;
    margin: 0 calc(var(--padding-base) * -1);
    background: var(--white);
    border-top: 1px solid var(--secondary-lighter);
}


.overview-client-mini .overview-item {
    padding: 10px 0;
    width: 100%;
}

.overview-client-mini .overview-item:not(:last-child) {
    border-bottom: 1px solid var(--secondary-lighter);
}

.overview-client-mini .overview-panel:not(:last-child) {
    padding-bottom: 10px;
}

.overview-client-mini .overview-item {
    padding: 10px 0;
}

a.button {
    line-height: 1.5;
}
