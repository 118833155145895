.CustomRangeForm > .Paragraph {
    margin-bottom: var(--margin-base);
}

.CustomRangeForm > .datepicker-form-group {
    margin-top: calc(var(--margin-base));
}

.CustomRangeForm__date-picker {
    z-index: 1100;
}

/* To fix datepicker position for mobile and tablet */
.CustomRangeForm__date-picker.additiv-picker-dropdown-hidden {
    display: none;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    .CustomRangeForm__date-picker {
        position: fixed;
        top: 0!important;
        bottom: 0;
        right: 0;
        left: 0;
        display: inline-flex;
        overflow: auto;
        justify-content: center;
    }

    .CustomRangeForm__date-picker .additiv-picker-panel-container {
        margin-top: 10px;
    }

    .Modal {
        top: 50px;
    }
}

@media screen and (max-width: 768px) {
    .CustomRangeForm__date-picker {
        position: fixed;
        width: 100%;
        top: 0!important;
        bottom: 25px;
        right: 0;
        left: 0;
        display: inline-flex;
        overflow: auto;
        align-items: center;
        justify-content: center;
    }
}
