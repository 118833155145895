.client-details .row {
    --row-margin-bottom: 25px;
}

.client-details .details-section .row .row {
    --row-margin-bottom: 0;
}

.client-details .details-section {
    max-width: 560px;
    width: 100%;
    margin-top: var(--padding-base);
    border-bottom: var(--border-base);
    margin-left: auto;
    margin-right: auto;
}

.client-details .details-section:last-child {
    border-bottom: none;
}

.client-details .details-section .col-7 {
    padding-right: 0;
}

.client-details .button-group {
    margin-top: var(--padding-base);
    padding-top: var(--padding-base);
    border-top: var(--border-base);
    margin-left: calc(-1 * var(--padding-base));
    margin-right: calc(-1 * var(--padding-base));
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
}

.client-details .col-buttons {
    padding-top: 5px;
}

.client-details .col-buttons button {
    margin-left: var(--margin-base);
}
