.payments-progress {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payments-progress .Steps-container {
    width: 400px;
}

.payments-progress .Steps-label-vertical .Steps-item-title {
    margin-left: -20px;
    width: 150px;
}

.payment-selection .radio-button-container {
    margin: 0 10px;
}

.payment-selection .radio-buttons .radio-button-container:last-child {
    margin-right: -10px;
}

.payment-selection .radio-buttons .radio-button-container:nth-child(2) {
    margin-left: 0;
}

.payment-selection .radio-button-container .info {
    width: 100%;
}

@media (max-width: 768px) {
    .payment-selection .radio-buttons .radio-button-container {
        margin: 0;
    }

    .payment-selection .radio-buttons .radio-button-container:last-child {
        margin-right: calc(var(--margin-base) / 2);
    }

    .payment-selection .radio-buttons .radio-button-container:first-child {
        margin-left: calc(var(--margin-base) / 2);
    }
}

.payments {
    margin-bottom: calc(var(--margin-base) * 2);
}

.payments .domestic-form,
.payments .international-form,
.payments .account-form {
    display: none;
}

.payments.international-active .international-form {
    display: block;
}

.payments.domestic-active .domestic-form {
    display: block;
}

.payments.account-active .account-form {
    display: block;
}

.payments .payment-content {
    margin: 0 auto;
    max-width: 880px;
    width: 100%;
}

.payments .payments-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-lighter);
    padding: 0 var(--padding-base);
    margin: 0 calc(var(--padding-base) * -1);
}

.payments .payment-selection {
    margin: var(--padding-base) calc(-1 * var(--padding-base));
    text-align: center;
}

.payments .payment-selection .radio-buttons {
    max-width: 880px;
    margin: 0 auto;
}

.payments .payment {
    border-top: 1px solid var(--secondary-lighter);
    margin: 0 calc(-1 * var(--padding-base)) calc(-1 * var(--padding-base));
}

.payments .form-group {
    margin-bottom: var(--margin-base);
    width: 50%;
}

.payments .credit-account {
    padding-top: var(--padding-base);
    border-top: 1px solid var(--secondary-lighter);
}

.payments .bank-details {
    padding-top: var(--padding-base);
    border-top: 1px solid var(--secondary-lighter);
}

.payments .payee {
    padding-top: var(--padding-base);
    border-top: 1px solid var(--secondary-lighter);
}

.payments .payment-details {
    padding-top: var(--padding-base);
    border-top: 1px solid var(--secondary-lighter);
}

.payments .debit-account {
    padding-top: var(--padding-base);
    border-top: 1px solid var(--secondary-lighter);
}

.payments .one-line {
    display: flex;
    width: 50%;
}

.payments .one-line > div:first-child {
    width: 40%;
    padding-right: var(--margin-base);
}

.payments .one-line > div:last-child {
    width: 60%;
}

.payments .one-line .form-group {
    width: 100%;
}

.payments .buttons-block-row {
    border-top: 1px solid var(--secondary-lighter);
}

.payments .toggle-block {
    display: flex;
    margin-bottom: var(--margin-base);
}

.payments .toggle-block .Toggle {
    margin-left: var(--margin-base);
}

.payments .one-line.execution-date > div:first-child {
    width: 60%;
}

.payments .one-line.execution-date > div:last-child {
    width: 40%;
    padding-top: 28px;
}

.payments .execution-date .additiv-picker {
    padding: 6px 12px;
}

.payments .amount {
    margin-bottom: var(--margin-base);
    width: 50%;
}

.payments .amount .form-group {
    margin-bottom: 0;
    width: 100%;
}

.payments .amount .form-group .TextInput-group-addon {
    padding: 0;
}

.payments .negative {
    display: flex;
    justify-content: left;
    align-items: center;
}

.payments .negative .Paragraph_primary {
    font-size: 1.8rem;
    margin-bottom: 0;
}

.payments .negative-icon {
    position: relative;
    width: 32px;
    height: 32px;
    background-color: var(--negative-default);
    border-radius: 30px;
}

.payments .negative-icon:hover {
    opacity: 1;
}

.payments .negative-icon:before,
.payments .negative-icon:after {
    position: absolute;
    left: 14px;
    top: 7px;
    height: 19px;
    width: 4px;
    content: ' ';
    border-radius: 2px;
    background-color: var(--white);
}

.payments .negative-icon:before {
    transform: rotate(45deg);
}

.payments .negative-icon:after {
    transform: rotate(-45deg);
}

.payments .create-account-inform {
    margin-top: var(--margin-base);
    margin-bottom: 0;
}
