.client-overview .table-wrapper {
    min-height: auto;
}

.client-overview > .Info-box {
    margin-bottom: calc(2 * var(--indent-base));
    align-items: center;
    padding: 16px 20px;
}

.client-overview > .Info-box > div {
    width: 100%;
}

.client-overview > .Info-box > div .row {
    margin-bottom: 0;
    align-items: center;
}

.client-overview > .Info-box > div .col-lg-3.col-sm-4 {
    text-align: right;
}

.client-overview > .Info-box > div .button {
    max-width: 100%;
}

.client-overview .LineChart .highcharts-tooltip > span {
    padding: 7px !important;
    white-space: nowrap !important;
}

.client-overview .information .Loader-wrapper,
.client-overview .documents .Loader-wrapper,
.client-overview .information .info-box_error,
.client-overview .documents .info-box_error {
    margin-bottom: var(--indent-base);
}

.client-overview .information .table-cell__icon {
    width: 40px;
    min-width: 40px;
}

.client-overview .information .Content-box {
    padding-bottom: var(--indent-base);
}

.client-overview .documents .Table tr td:nth-child(2) {
    max-width: 135px;
}

.client-overview .documents .Table tbody .table-cell_with-button-icon button .table-cell_icon {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
}

.client-overview .documents .Table-wrapper .Table-header-mini {
    padding-right: 0;
}

.client-overview .DocumentsSkeleton {
    display: flex;
    align-items: center;
    padding: 16px 0;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-lighter);
}

.client-overview .documents .Loader-wrapper,
.client-overview .messages .Loader-wrapper,
.client-overview .notes .Loader-wrapper,
.client-overview .portfolios .Loader-wrapper {
    margin-top: var(--padding-base);
}

.client-overview .messages .Loader-wrapper {
    margin-bottom: 0;
}

.client-overview .investment-allocation .Loader-wrapper {
    margin-bottom: 30px;
}

.client-overview .asset-development .Loader-wrapper {
    margin-bottom: 30px;
}

.client-overview .portfolios__empty {
    padding: 20px 0 10px;
    color: var(--primary-light);
    font-size: 1.4rem;
    line-height: 1.14285714;
    text-align: center;
}

.client-overview .portfolios__button {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    margin: 0 -30px;
    padding: 20px 30px 0;
    border-top: 1px solid var(--secondary-lighter);
}

.client-overview .investment-allocation .table-wrapper {
    margin-top: calc(var(--padding-base) * -1);
}

.client-overview .documents .Title,
.client-overview .messages .Title,
.client-overview .notes .Title {
    margin: calc(var(--padding-base) * -1) calc(var(--padding-base) * -1) 0 calc(var(--padding-base) * -1);
    padding: var(--padding-base);
    border-bottom: 1px solid var(--secondary-lighter);
}

.client-overview .overview .row {
    margin-bottom: 0;
}

.client-overview .investment-allocation .Title {
    margin-bottom: 10px;
}

.client-overview .investment-allocation .Tabs-content .TabPane .PieChart,
.client-overview .investment-allocation .Tabs-content .TabPane .Highcharts-bar-chart {
    padding-top: 5px;
    margin-left: calc(var(--margin-base) / -2);
}

.client-overview .investment-allocation .Tabs-content .TabPane .Table {
    margin-top: 15px;
}

.client-overview .asset-development .Title {
    margin-bottom: 10px;
}

.client-overview .asset-development .Tabs-bar {
    margin-bottom: calc(var(--margin-base) + 10px);
}

.client-overview .asset-development .Tabs {
    margin-bottom: -10px;
}

.client-overview .messages .ResizeHeight {
    margin-bottom: -35px;
}

.client-overview .messages .ResizeHeight .resize-bottom {
    bottom: calc(var(--margin-base) / 2);
}

@media only screen and (max-width: 1200px) {
    .client-overview .PieChart .highcharts-pie {
        min-width: var(--pie-chart-container-min-width-tablet);
        width: var(--pie-chart-container-width-tablet);
    }
}

.client-overview .messages .widget__empty {
    padding: var(--padding-base) 0 0;
}

.client-overview .documents .widget__empty {
    margin-bottom: 0;
    padding: 30px 0 0;
}

.client-overview .tasks .Table-header-mini {
    padding: var(--table-mini-cell-vertical-indents);
}

.client-overview .tasks .Table-content {
    padding: 0;
}
