/* .documents-page {
    margin-top: var(--padding-base);
} */

.documents-page .search-wrapper {
    margin: var(--padding-base) auto;
    width: 75%;
}

.documents-page .PageHeader-title .Title {
    font-size: var(--title-2-fz);
}

.documents-page .documents-list {
    padding-top: 0;
}

.documents-page .content-right {
    text-align: right;
}

.documents-page .Pagination {
    margin: 0 calc(var(--indent-base) * -2);
}

.documents-page .col-buttons button {
    margin: var(--margin-base) var(--margin-base) 0 0;
}

@media only screen and (min-width: 576px) {
    .documents-page .col-buttons button {
        margin: 0 0 0 var(--margin-base);
    }
}

.documents-page .checkbox-wrapper {
    align-items: center;
}

.documents-page .table-wrapper {
    width: auto;
    margin: 0 calc(var(--indent-base) * -2);
    padding: 0 calc(var(--indent-base) * 2);
    border-top: 1px solid var(--secondary-lighter);
}

.documents-page .table-cell_linked {
    cursor: pointer;
    text-decoration: underline;
}

.documents-page .table-cell_disabled .table-cell_linked,
.documents-page .table-cell_disabled .table-cell_linked span {
    text-decoration: none;
}

.documents-page .table-row:last-child .table-cell {
    border-bottom: 0;
}

.documents-page .table-cell_icon--header {
    color: inherit;
}

.documents-page .widget__empty {
    color: var(--primary-light);
    margin-bottom: 0;
    text-align: center;
}

.documents-page .table tr th.column-fixed {
    text-align: center;
}

.documents-page .table tr td.cell-fixed {
    background-color: transparent;
}

.documents-page .table-cell_account {
    padding-right: 20px!important;
}

.documents-page tbody > tr td:not(:last-child) {
    padding-right: var(--table-filter-width);
}

.documents-page .Table:not(.hasPagination) tbody tr:last-child td {
    padding-bottom: 0;
}

.documents-page .Tabs-bar {
    border-bottom: none;
    margin-bottom: 0;
}

.Modal .documents-input {
    margin: 2rem 0;
    min-height: 100px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.Modal .documents-input .form-group {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 100%;
}

.Modal .documents-input .form-group .FilesList {
    max-width: 100%;
}
