.investment-performance .AccordionPanel-content.AccordionPanel-content__open,
.analysis .AccordionPanel-content.AccordionPanel-content__open {
    z-index: 2;
    overflow: visible;
}

.analysis .column-center {
    margin: auto;
    justify-self: center;
    grid-column: 1 / -1;
}

.analysis .AccordionPanel-content-content {
    overflow: visible;
}

.analysis .AccordionPanel-content-box {
    padding-bottom: var(--indent-base);
}

.analysis .Info-box {
    margin: var(--indent-base) auto;
}

.analysis .Risk-return-container {
    margin: 0 auto var(--indent-base);
    width: fit-content;
    text-align: center;
}

.analysis .Risk-return-container  .Risk-return-legend {
    text-align: center;
}

.analysis .highcharts-container .highcharts-background {
    width: 0;
}

.analysis .Loader-wrapper {
    margin: 0 auto !important;
    padding-bottom: var(--indent-base);
}

.analysis .highcharts-tooltip > span {
    padding: 7px !important;
    outline: 7px solid var(--white);
    background: var(--white);
    white-space: nowrap !important;
}

.analysis .highcharts-tooltip > span .value {
    margin-left: 10px;
}

.analysis .Tabs-content .TabPane > .row {
    margin-bottom: 0;
}

.analysis .Tabs-bar .Tabs-bar__extra .extra-select {
    margin-left: auto;
}

.analysis .Tabs-bar .Tabs-bar__extra .selects__more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.analysis .Tabs-bar .Tabs-bar__extra .selects__more > div {
    width: 50%;
}

.analysis .Tabs-bar .Tabs-bar__extra .selects__more > div:first-child {
    margin-right: var(--margin-base);
}

.analysis .Tabs-bar .Tabs-bar__extra .selects__more .form-group {
    max-width: 100%;
}

@media (max-width: 990px) {
    .analysis .Tabs-bar .Tabs-bar__extra {
        width: calc(50% - var(--margin-base));
    }

    .analysis .Tabs-bar .Tabs-bar__extra .selects__more .select {
        width: 100% !important;
    }
}

.analysis .Tabs-bar .Tabs-bar__extra .selects__more .select {
    max-width: 100%;
}

.analysis .widget__empty {
    text-align: center;
    margin-bottom: var(--margin-base);
}
