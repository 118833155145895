.single-portfolio {
    margin-bottom: 40px !important;
}

.single-portfolio .Info-box {
    margin-bottom: calc(var(--indent-base) * 2);
}

.single-portfolio .LineChart .highcharts-tooltip > span {
    padding: 11.5px 7px !important;
    white-space: nowrap !important;
}

.single-portfolio .overview-panel {
    padding-top: var(--padding-base);
}

.single-portfolio .overview-panel .overview-item {
    align-items: flex-start;
}

.single-portfolio .overview-panel .overview-item:first-child {
    margin-bottom: var(--padding-base);
}

.single-portfolio .overview-panel .overview-item .overview-item__value {
    height: 38px;
    line-height: 38px;
}

.single-portfolio .positions-table tr th.column-fixed {
    text-align: center;
}

.single-portfolio .positions-table tr td.cell-fixed {
    background-color: transparent;
}

.single-portfolio .positions .table-cell__actions {
    padding: 0;
    height: 30px;
}

.single-portfolio .positions .table-cell__actions .Dropdown-container {
    height: 100%;
}

.single-portfolio .positions .table-cell__actions .Dropdown-container .Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: var(--table-bg);
}

.single-portfolio .positions .AccordionPanel-content-box {
    padding-bottom: 10px;
}

.single-portfolio .positions .Loader-wrapper {
    margin-bottom: var(--indent-base);
}

.single-portfolio .Accordion {
    width: calc(100% + 2 * var(--content-box-p));
    margin-left: calc(-1 * var(--content-box-p));
    margin-right: calc(-1 * var(--content-box-p));
}

.single-portfolio .positions.AccordionPanel__active .AccordionPanel-header {
    padding-bottom: 14px;
}

.single-portfolio .positions .widget__empty {
    margin-bottom: 20px !important;
}

.single-portfolio .table-wrapper .Pagination {
    padding-bottom: 20px;
}

.single-portfolio .Content-box .button,
.single-portfolio .PageHeader .button {
    margin-left: var(--margin-base);
    margin-top: 12px;
}

.single-portfolio .transactions_cash-flows {
    margin-top: -15px;
}

.single-portfolio .transactions_cash-flows .Tabs-bar {
    margin-bottom: 0;
}

.single-portfolio .transactions {
    border-top: none;
}

.single-portfolio .allocation .table-wrapper {
    margin-top: calc(calc(var(--padding-base) * -1) / 2) !important;
    margin-bottom: calc(var(--indent-base) * -1) !important;
}

.single-portfolio .analysis.AccordionPanel__active .AccordionPanel-header,
.single-portfolio .allocation.AccordionPanel__active .AccordionPanel-header {
    padding-bottom: 8px;
}

.single-portfolio .analysis .Tabs,
.single-portfolio .allocation .Tabs {
    margin-top: 0 !important;
}

.single-portfolio .BreadCrumbs {
    margin-bottom: 5px;
}

.single-portfolio .buttons-block {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.single-portfolio .investment-allocation .Tabs-content .TabPane {
    padding-top: 5px;
    margin-left: calc(var(--margin-base) / -2);
}

.single-portfolio .investment-allocation {
    margin-bottom: -10px;
}

.single-portfolio .analysis .Tabs-content .TabPane > .row {
    margin-bottom: calc(var(--margin-base) / -1);
}

.single-portfolio .investment-performance.AccordionPanel__active .AccordionPanel-header {
    padding-bottom: 18px;
}

.single-portfolio .investment-performance .analysis .Tabs-content {
    padding-top: 15px;
}

.single-portfolio .Table thead > tr th:last-child .filter.select__multi-mini {
    margin-right: 0;
}

.single-portfolio .portfolio-updated {
    margin-bottom: 0;
    margin-left: calc(var(--margin-base) / -2);
}

.single-portfolio .title-content .Title {
    margin-bottom: 5px;
    margin-left: calc(var(--margin-base) / -2);
}

.single-portfolio .analysis .Info-box {
    margin-bottom: calc(var(--margin-base));
}

.single-portfolio .investment-performance .analysis .BandwidthChart-container {
    padding-bottom: 15px;
}

.single-portfolio .investment-performance .analysis .Highcharts-risk-return {
    margin-top: calc(var(--margin-base) / -2);
}

.single-portfolio .row-margin-left td:first-child {
    padding-left: var(--padding-base);
}

.single-portfolio .buttons-block-row {
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
}

.single-portfolio .access-policy:last-child {
    margin-bottom: -10px;
}

.single-portfolio .access-policy span {
    margin-right: 5px;
}

.actionBox {
    min-width: auto;
}

.actionBox .Icon-action{
    padding: 0;
}

.single-portfolio .additiv-widgets--investment-allocation .PieChart .highcharts-pie {
    width: 100%;
    justify-content: center;
}
