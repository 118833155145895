.client-search .SearchBar {
    margin-top: calc(var(--margin-base) * -1);
}

.client-search .SearchBar .AutoComplete {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--secondary-lighter) !important;
    line-height: inherit;
    padding-top: 1px;
}

.client-search .SearchBar .AutoComplete .TextInput-affix-wrapper .TextInput-prefix {
    left: 0;
    font-size: 1.4rem;
    width: 20px;
    justify-content: start;
}

.client-search .SearchBar .AutoComplete .form-group input.TextInput {
    height: auto;
    padding: 10px 15px 10px 5px;
    font-size: 1.4rem;
    line-height: 1.5rem;
}

.client-search .SearchBar .AutoComplete-dropdown {
    left: 0;
    box-shadow: 0 1px 10px 0 var(--secondary-lighter);
    border: none;
}
