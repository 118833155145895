.radio-buttons {
    --radio-buttons-height: 150px;
    --radio-buttons-height-sm: 115px;
    --radio-buttons-height-xs: 100px;
    display: flex;
    justify-content: space-between;
}

.radio-buttons + .Info-box {
    margin-top: var(--margin-base);
}

.radio-buttons legend {
    display: none;
}

.radio-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.radio-button-container {
    position: relative;
    align-items: center;
    display: flex;
    width: 100%;
    height: var(--radio-buttons-height);
    color: var(--white);
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: 152px;
    max-width: 152px;
    margin: 10px 0;
}

.without-img {
    border: 1px solid var(--primary-lighter);
    color: var(--primary-default);
}

.without-img input:checked ~ .info {
    color: var(--white);
}

.radio-button-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.info {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    word-break: break-word;
    padding: calc(var(--padding-base) / 2);
}

.checkmark img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.checkmark-img {
    position: absolute;
    width: 100%;
    height: 100%;
}

.checkmark-img:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.4;
    content: '';
    background-color: var(--primary-default);
}

.checkmark:after {
    position: absolute;
    z-index: 1;
    display: none;
    content: '';
}

.radio-button-container input:checked ~ .checkmark:after {
    display: block;
}

.radio-button-container .checkmark:after {
    width: 100%;
    height: 100%;
    background: var(--accent-default);
}

.check {
    display: inline-block;
    transform: rotate(45deg);
    height: 28px;
    width: 12px;
    border-bottom: 4px solid var(--white);
    border-right: 4px solid var(--white);
    margin-bottom: 10px;
}

/* general rule for child numbers outside of defined on items 1 - 6 */
.radio-buttons-container .radio-button-container:first-child:nth-last-child(even),
.radio-buttons-container .radio-button-container:first-child:nth-last-child(even) ~ .radio-button-container {
    max-width: 23%;
}

.radio-buttons-container .radio-button-container:first-child:nth-last-child(odd),
.radio-buttons-container .radio-button-container:first-child:nth-last-child(odd) ~ .radio-button-container {
    max-width: 31.33%;
}

/* one item */
.radio-buttons-container .radio-button-container:first-child:nth-last-child(1),
.radio-buttons-container .radio-button-container:first-child:nth-last-child(1) ~ .radio-button-container {
    max-width: 100%;
}

/* two items */
.radio-buttons-container .radio-button-container:first-child:nth-last-child(2),
.radio-buttons-container .radio-button-container:first-child:nth-last-child(2) ~ .radio-button-container {
    max-width: 48%;
}

/* three items */
.radio-buttons-container .radio-button-container:first-child:nth-last-child(3),
.radio-buttons-container .radio-button-container:first-child:nth-last-child(3) ~ .radio-button-container {
    max-width: 31.33%;
}

/* four items */
.radio-buttons-container .radio-button-container:first-child:nth-last-child(4),
.radio-buttons-container .radio-button-container:first-child:nth-last-child(4) ~ .radio-button-container {
    max-width: 23%;
}

/* five items */
.radio-buttons-container .radio-button-container:first-child:nth-last-child(5),
.radio-buttons-container .radio-button-container:first-child:nth-last-child(5) ~ .radio-button-container {
    max-width: 18%;
}

/* six items */
.radio-buttons-container .radio-button-container:first-child:nth-last-child(6),
.radio-buttons-container .radio-button-container:first-child:nth-last-child(6) ~ .radio-button-container {
    max-width: 31.33%;
}

@media screen and (min-width: 577px) and (max-width: 1024px) and (orientation: portrait) {
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(2n),
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(2n) ~ .radio-button-container {
        max-width: 48%;
    }

    .radio-buttons-container .radio-button-container:first-child:nth-last-child(3n),
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(3n) ~ .radio-button-container {
        max-width: 31.33%;
    }

    .radio-buttons-container .radio-button-container:first-child:nth-last-child(5),
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(5) ~ .radio-button-container {
        max-width: 48%;
    }
}

@media screen and (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(2n),
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(2n) ~ .radio-button-container {
        max-width: 48%;
    }

    .radio-buttons-container .radio-button-container:first-child:nth-last-child(3n),
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(3n) ~ .radio-button-container {
        max-width: 31.33%;
    }

    .radio-buttons-container .radio-button-container:first-child:nth-last-child(5),
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(5) ~ .radio-button-container {
        max-width: 23%;
    }

    .radio-buttons-container .radio-button-container:first-child:nth-last-child(8),
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(8) ~ .radio-button-container {
        max-width: 23%;
    }
}

@media only screen and (max-width: 1024px) {
    .radio-button-container {
        height: var(--radio-buttons-height-sm);
    }
}

@media only screen and (max-width: 576px) {
    .radio-buttons {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .radio-button-container {
        width: calc(50% - var(--margin-base) / 2);
        max-width: 100%;
        margin: 0 0 var(--margin-base) 0;
        height: var(--radio-buttons-height-xs);
    }

    .radio-button-container:last-child {
        margin-bottom: 0;
    }

    .info {
        width: 120px;
    }

    /* items */
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(1n),
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(1n) ~ .radio-button-container {
        max-width: 48%;
    }

    /* one item */
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(1),
    .radio-buttons-container .radio-button-container:first-child:nth-last-child(1) ~ .radio-button-container {
        max-width: 100%;
    }

    .radio-buttons-container .radio-button-container:last-child:nth-child(2n+1) {
        max-width: 100% !important;
        width: 100%;
    }
}
