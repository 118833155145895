.portfolio-risk-profile {
    min-height: calc(100vh - 182px);
    display: grid;
}

.portfolio-risk-profile .Steps-container {
    margin: 30px 0;
}

.portfolio-risk-profile .stepper-content {
    margin: 30px auto 0;
    max-width: 880px;
    width: 100%;
}

.portfolio-risk-profile .Info-box {
    display: inline-flex;
    margin: var(--indent-base) 0;
}

.portfolio-risk-profile .investment-horizon .ant-slider-with-marks {
    margin-bottom: calc(var(--indent-base) * 4);
}

.portfolio-risk-profile .stepper-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    border-top: 1px solid var(--secondary-lighter);
}

.portfolio-risk-profile .stepper-buttons .button-primary {
    margin-left: auto;
}
