.edit-profile {
    margin-top: var(--padding-base);
}

.edit-profile .row {
    --row-margin-bottom: 25px;
}

.edit-profile .edit-profile-section .row .row {
    --row-margin-bottom: 0;
}

.edit-profile .edit-profile-section {
    max-width: 560px;
    width: 100%;
    margin-left: var(--margin-base);
    border-bottom: var(--border-base);
    margin-right: auto;
}

.edit-profile .edit-profile-section:not(:first-child) {
    margin-top: var(--padding-base);
}

.edit-profile .edit-profile-section:last-child {
    border-bottom: none;
}

.edit-profile .edit-profile-section .col-7 {
    padding-right: 0;
}

.edit-profile .button-group {
    margin-top: var(--padding-base);
    padding-top: var(--padding-base);
    border-top: var(--border-base);
    margin-left: calc(-1 * var(--padding-base));
    margin-right: calc(-1 * var(--padding-base));
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
}

.edit-profile .col-buttons {
    padding-top: 5px;
}

.edit-profile .col-buttons button {
    margin-left: var(--margin-base);
}

.edit-profile .Avatar {
    --avatar-large-size: 72px;
}

.edit-profile .Avatar img {
    clip-path: circle();
    min-width: var(--avatar-large-size);
    min-height: var(--avatar-large-size);
}

.edit-profile .upload-buttons {
    display: flex;
    margin-top: var(--margin-base);
}

.edit-profile .upload-buttons button {
    min-width: var(--btn-small-min-w);
    margin-right: var(--margin-base);
}

.edit-profile .left-menu {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.edit-profile .left-menu .parent-link {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: var(--indent-base);
}

.edit-profile .left-menu .parent-link.active {
    color: var(--primary-default);
}

.edit-profile .left-menu .left-menu-link {
    color: var(--secondary-default);
    margin-left: calc(var(--margin-base) / 2);
    font-size: 14px;
    margin-bottom: var(--margin-base);
}

.edit-profile .left-menu .left-menu-link.active {
    color: var(--primary-default);
    font-weight: bold;
}

.edit-profile input.internalIdField {
    width: 300px;
    border: none;
    background-color: transparent;
}

.edit-profile input.internalIdField:focus-visible, .edit-profile input.internalIdField:focus {
    outline: none;
}
