.next-up .table-wrapper {
    margin-top: 0 !important;
}

.NextUpSkeleton {
    padding: 16px 0;
    border-bottom: 1px solid var(--secondary-lighter);
}

.NextUpSkeleton .next-up-skeleton-content {
    display: flex;
    flex-direction: column;
    border-left: 6px solid var(--secondary-lighter);
    padding-left: 13px;
}

.NextUpSkeleton .next-up-skeleton-content > .Skeleton:first-child {
    margin-bottom: 5px;
}

.NextUpSkeleton .next-up-skeleton-content > .Skeleton:nth-child(2) {
    margin-bottom: 10px;
}
