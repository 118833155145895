.single-news-item {
    --screen-md-min: 767px;

    padding: var(--padding-base) 0;
    margin: 0 !important;
}

.single-news-item hr {
    display: block;
    height: 1px;
    margin: 0;
    border: none;
    color: var(--secondary-lighter);
    background-color: var(--secondary-lighter);
}

@media (max-width: 768px ) {
    hr {
        display: none;
    }
}

.single-news-item .Content-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.single-news-item .Content-box .button {
    min-width: 60px;
    margin-left: var(--margin-base);
}

.single-news-item--image {
    max-width: 100%;
}

.single-news-item--date {
    min-width: 100px;
    line-height: 1.7;
    margin-bottom: 10px;
}

.single-news-item--content {
    margin-bottom: 9px;
    white-space: pre-line;
}

.single-news-item .Title {
    margin-bottom: 0;
}

.single-news-item .news-item-content .row {
    margin-bottom: 0;
}
