.portfolios__empty {
    padding: 20px 0 10px;
    font-size: 1.4rem;
    color: var(--primary-light);
    line-height: 1.14285714;
    text-align: center;
}

.portfolios__button {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    margin: 0 -30px;
    padding: 20px 30px 0;
    border-top: 1px solid var(--secondary-lighter);
}

.portfolios .Table thead > tr th:last-child {
    text-align: right;
}

.portfolios .Table thead > tr th:last-child .filter.select__multi-mini {
    margin-right: 0;
}

.portfolios .Table .Dropdown-container {
    text-align: right;
}

.portfolios .PageHeader-title .Title {
    font-size: var(--title-2-fz);
}

.portfolios .table-wrapper {
    width: auto;
    margin: 0 calc(calc(var(--indent-base) * 2) * -1) calc(calc(var(--indent-base) * 2) * -1);
    padding: 0 calc(var(--indent-base) * 2) calc(var(--indent-base) * 2);
}
