hr.dashboard-divider {
    margin: 0 calc(var(--padding-base) * -1) 20px;
    height: 1px;
    border: 0;
    background: var(--secondary-lighter);
}

.skeleton-components > *:first-child {
    border-top: 1px solid var(--secondary-lighter);
}

.skeleton-components > *:last-child {
    border-bottom: none;
}
