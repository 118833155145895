.new-portfolio_modify .modify-content .Table-wrapper {
    margin-top: calc(-1 * var(--content-box-p));
}

.new-portfolio_modify .Table tr td {
    padding: 0;
    height: 56px;
}

.new-portfolio_modify .Table tr td:first-child {
    align-items: center;
}

.new-portfolio_modify .Table tr:not(.row-level-0) td:first-child {
    overflow: visible;
}

.new-portfolio_modify .Table tr td.modify-goal__cell-new {
    padding: 0 var(--table-cell-horizontal-indents) 0 50px;
}

.new-portfolio_modify .Table tr td.modify-goal__cell-with-icons {
    padding-left: var(--table-cell-horizontal-indents);
}

.new-portfolio_modify .Info-box {
    margin-bottom: var(--padding-base);
}
