.interaction {
    margin-top: calc(var(--indent-base) * 2);
    margin-bottom: calc(var(--indent-base) * 2);
}

.interaction .Content-box:not(:last-child) {
    border-bottom: 1px solid var(--secondary-lighter);
}

.interaction .content-box_title .Title {
    margin-bottom: 0;
}

.interaction .interaction-content {
    padding-bottom: var(--margin-base);
}

.interaction .content-wrapper {
    max-width: 800px;
    margin: 0 auto;
}

.interaction .ant-tabs-bar {
    margin-bottom: 0;
}

.interaction .interaction-details {
    border-top: 0;
    padding-top: calc(var(--padding-base) / 2);
    padding-bottom: 0;
}

.interaction .existing-interaction .comment,
.interaction .interaction-details .comment {
    margin-bottom: 0;
}

.interaction .buttons-block {
    padding-top: var(--padding-base);
}

.interaction .buttons-block .row {
    margin-bottom: 0;
}
