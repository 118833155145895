.PageUnderConstruction {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 182px);
    min-height: 300px;
}

.PageUnderConstruction .Icon {
    margin-bottom: 20px;
}

.PageUnderConstruction .page-title {
    margin: 30px 0 0;
    text-align: center;
}

.PageUnderConstruction p.Paragraph_secondary {
    margin-bottom: 15px;
}

.PageUnderConstruction .button {
    width: 100%;
}
