.loading-overlap {
    position: relative;
}

.loading-overlap:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: '';
    /* stylelint-disable */
    background-color: rgba(var(--white-rgb), 0.5);
    /* stylelint-enable */
}

.loading-overlap .overlap-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
}

.loading .overlap-loader .Loader-wrapper {
    margin: 0;
}
