.formio-wrapper .formio-component .row {
    --row-margin-bottom: var(--margin-base);

    width: 100%;
}

.formio-wrapper .alert {
    display: none;
}

.formio-wrapper .card-body .row.formio-component-columns > .col-md-10 {
    grid-column: span 12;
}

.formio-wrapper .formio-component .row .col-md-2,
.formio-wrapper .formio-component .row .col-md-3,
.formio-wrapper .formio-component .row .col-md-4 {
    grid-column: span 6;
}

.formio-wrapper .formio-component .row .col-md-9 {
    grid-column: span 12;
}

.formio-wrapper .formio-component .row .col-md-10 > .row,
.formio-wrapper .datagrid-table td:only-child > .row {
    width: calc(100% + 2 * var(--grid-column-padding));
}

@media (max-width: 768px) {
    .formio-wrapper .formio-component .row .col-md-3 {
        grid-column: span 12;
    }
}

.formio-wrapper .formio-component .row .row {
    --row-margin-bottom: 0;
}

/* Form Errors */
.formio-error-wrapper,
.formio-warning-wrapper {
    padding: 0;
}

.formio-error-wrapper {
    color: var(--negative-default);
    background-color: transparent;
    border-color: transparent;
}

/* Label hidden */
.formio-wrapper .formio-hidden {
    display: none;
}

/* Form Group */
.formio-wrapper .form-group:not(.formio-component-datagrid) {
    --form-group-mb: var(--margin-base);
}

.formio-wrapper .formio-form > .formio-component:last-child > .card > .card-body > .form-group:last-child {
    margin-bottom: 0;
}

/* Titles */
.formio-wrapper .formio-component legend {
    --title-3-fz: 18px;
    --title-3-lh: 18px;
    --title-3-margin-bottom: 24px;

    width: 100%;
    font-size: var(--title-3-fz);
    line-height: var(--title-3-lh);
    font-family: var(--accent-font-family);
    font-weight: normal;
    margin-bottom: var(--title-3-margin-bottom);
    padding-bottom: calc(var(--margin-base) / 2);
    border-bottom: var(--border-base);
}

.formio-wrapper .formio-component-htmlelement h4,
.formio-wrapper .formio-component-htmlelement > label {
    --title-4-fz: 16px;
    --title-4-lh: 1.375;
    --title-4-margin-bottom: 22px;

    font-size: var(--title-4-fz);
    line-height: var(--title-4-lh);
    font-family: var(--body-font-family);
    font-weight: normal;
    margin-bottom: var(--title-4-margin-bottom);
}

.formio-wrapper .formio-component h4 {
    font-weight: bold;
}

/* Label */
.formio-wrapper label.col-form-label {
    --label-m: 0 0 calc(var(--margin-base) / 2);
    --label-fz: 14px;
    --label-lh: 1.28571429;
    --label-color: var(--label-color-theme, var(--primary-default));
    --label-icon-p: 3px;
    --label-icon-fz: 10px;
    --label-icon-color: var(--label-icon-color-theme, var(--secondary-default));

    display: block;
    justify-content: flex-start;
    align-items: flex-start;
    margin: var(--label-m);
    font-size: var(--label-fz);
    color: var(--label-color);
    line-height: var(--label-lh);
    letter-spacing: 0.5px;
    font-family: var(--accent-font-family);
    font-weight: var(--label-font-weight-theme, var(--accent-font-weight));
}

.formio-wrapper label.col-form-label.field-required:after {
    color: var(--label-color);
}

/* Errors */
.formio-wrapper .formio-errors .error {
    display: inline-block;
    color: var(--negative-default);
}

/* Accordion component */
.formio-wrapper .formio-form > .formio-component:nth-last-child(2) .card {
    border-bottom: none;
}

.formio-wrapper .formio-form > .formio-component-panel .card-header {
    pointer-events: none;
    margin-top: var(--padding-base);
    margin-bottom: var(--margin-base);
}

.formio-wrapper .formio-form > .formio-component-panel .card-header .card-title {
    --title-3-fz: 18px;
    --title-3-lh: 24px;
    font-size: var(--title-3-fz);
    line-height: var(--title-3-lh);
}

.formio-wrapper .formio-form > .formio-component-panel .card-header .card-title .formio-collapse-icon {
    display: none;
}

.formio-wrapper .card-body .formio-component-container > div > .row.formio-component-columns:not(.formio-hidden):not(:last-child):after {
    position: absolute;
    left: var(--grid-column-padding);
    bottom: 0;
    display: block;
    width: calc(100% - 2 * (var(--grid-column-padding)));
    content: '';
    border-bottom: var(--border-base);
}

.formio-wrapper .fieldset-body > .row.formio-component-columns:not(.formio-hidden):not(:last-child) {
    border-bottom: 1px solid var(--secondary-lighter);
}

.formio-wrapper .card-body > .row.formio-component,
.formio-wrapper .card-body .formio-component-container > div > .row.formio-component-columns:last-child {
    margin-bottom: 0;
}

.formio-wrapper .card-body .row.formio-component-columns .formio-component-htmlelement {
    margin-bottom: var(--margin-base);
    scroll-margin-top: var(--margin-base);
}

.formio-wrapper .card-body .formio-component .form-group:not(.formio-component-datagrid) {
    margin-bottom: var(--margin-base);
}

/* Table components */
.formio-wrapper .table.datagrid-table {
    width: 100%;
}

.formio-wrapper .table.datagrid-table tbody td {
    padding-bottom: calc(var(--padding-base) / 2);
    border-bottom: 1px solid var(--secondary-lighter);
}

.formio-wrapper .table.datagrid-table tr:not(:first-child) td {
    padding-top: calc(var(--padding-base) / 2);
}

.formio-wrapper .table.datagrid-table tr td:last-child:not(:first-child) {
    width: 1%;
    white-space: nowrap;
}

.formio-wrapper .table.datagrid-table tfoot td {
    padding-top: calc(var(--padding-base) / 2);
    padding-bottom: calc(var(--padding-base) / 2);
}

.formio-wrapper .table.datagrid-table .formio-button-add-row {
    margin: auto 0 auto auto;
}

/* Button component */
.formio-wrapper .btn {
    --btn-small-p: 3px calc(var(--padding-base) / 3);
    --btn-small-h: 30px;
    --btn-small-fz: var(--font-size-base);
    --btn-small-lh: 1.142;
    --btn-color: var(--btn-color-theme, var(--primary-default));
    --btn-border: var(--border-base);
    --btn-border-radius: var(--btn-border-radius-theme, var(--border-radius-base));

    --btn-primary-bg: var(--btn-primary-bg-theme, var(--accent-default));
    --btn-primary-color: var(--btn-primary-color-theme, var(--white));
    --btn-primary-hover-bg: var(--btn-primary-hover-bg-theme, var(--accent-dark));
    --btn-primary-hover-border-color: var(--btn-primary-hover-border-color-theme, var(--accent-dark));

    --btn-secondary-border-color: var(--btn-secondary-border-color-theme, var(--secondary-light));
    --btn-secondary-hover-bg: var(--btn-secondary-hover-bg-theme, var(--white));
    --btn-secondary-hover-color: var(--btn-secondary-hover-color-theme, var(--primary-default));
    --btn-secondary-hover-border-color: var(--btn-secondary-hover-border-color-theme, var(--secondary-default));

    --btn-danger-bg: var(--btn-danger-bg-theme, var(--negative-default));
    --btn-danger-color: var(--btn-danger-color-theme, var(--white));
    --btn-danger-hover-bg: var(--btn-danger-hover-bg-theme, var(--negative-dark));
    --btn-danger-hover-border-color: var(--btn-danger-hover-border-color-theme, var(--negative-dark));

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--btn-small-h);
    padding: var(--btn-small-p);
    box-shadow: none;
    border: var(--btn-border);
    outline: 0;
    color: var(--btn-color);
    font-weight: 400;
    font-size: var(--btn-small-fz);
    line-height: var(--btn-small-lh);
    white-space: nowrap;
    text-align: center;
    background-image: none;
    background-color: transparent;
    cursor: pointer;
    transition: var(--transition);
    user-select: none;
    touch-action: manipulation;
    border-radius: var(--btn-border-radius);
    border-color: var(--btn-secondary-border-color);
}

.formio-wrapper .btn:hover {
    background-color: var(--btn-secondary-hover-bg);
    border-color: var(--btn-secondary-hover-border-color);
    color: var(--btn-secondary-hover-color);
}

.formio-wrapper .Loader-wrapper .loader {
    position: unset;
}

.formio-wrapper button i.fa {
    width: var(--btn-small-fz);
    height: var(--btn-small-fz);
    background-color: var(--btn-color);
}

.formio-wrapper button i.fa:before {
    content: '';
}

.formio-wrapper .formio-drag-button {
    display: none;
}

/* Delete (close) and Add (plus) button */
.formio-wrapper button.btn.formio-button-remove-row {
    background-color: var(--btn-danger-bg);
    border-color: var(--btn-danger-bg);
    color: var(--btn-danger-color);
    text-align: center;
    margin-top: 6px;
}

.formio-wrapper button.btn.formio-button-remove-row:hover {
    background-color: var(--btn-danger-hover-bg);
    border-color: var(--btn-danger-hover-border-color);
}

.formio-wrapper button.btn.formio-button-remove-row i.fa {
    mask: url('~ui-library/dist/assets/icons/close.svg') no-repeat center;
    mask-size: cover;
    background-color: var(--btn-danger-color);
}

.formio-wrapper button.btn.formio-button-add-row i.fa,
.formio-wrapper button.btn i.fa.fa-plus {
    mask: url('~ui-library/dist/assets/icons/plus.svg') no-repeat center;
    mask-size: cover;
}

/* Input component */
.formio-wrapper .TextInput-group {
    border: none;
}

.formio-wrapper .TextInput-affix-wrapper {
    height: auto;
}

.formio-wrapper .TextInput-group-wrapper input {
    width: inherit;
    padding: 0 var(--text-input-horizontal-padding);
    height: var(--text-input-height-base);
    background-color: var(--text-input-bc);
    border: var(--text-input-border);
    font-size: var(--text-input-fz);
    color: var(--text-input-color);
    line-height: var(--text-input-lh);
}

.formio-wrapper .TextInput-group-wrapper input:hover {
    border: var(--text-input-border);
    border-color: var(--text-input-group-hover-border-color);
}

.formio-wrapper .TextInput-group-wrapper input:focus {
    outline: none;
}

.formio-wrapper .TextInput-group-wrapper input[disabled] {
    color: var(--text-input-disabled-color);
    box-shadow: none;
    background: var(--text-input-bc);
    opacity: 0.5;
    pointer-events: none;
}

.formio-wrapper .TextInput-group-wrapper input.is-invalid {
    border-color: var(--text-input-error-hover-color);
}

.formio-wrapper .TextInput-group-wrapper .input-group {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.formio-wrapper .TextInput-group-wrapper .input-group-append {
    position: absolute;
    right: 10px;
    color: var(--text-input-affix-suffix-color);
    top: 50%;
    z-index: var(--text-input-affix-wrapper-zi);
    line-height: 0;
    transform: translateY(-50%);
    cursor: pointer;
}

/* Select component */
.formio-wrapper .select {
    min-height: var(--select-height);
    height: auto;
}

.formio-wrapper .select .fluid.selection.dropdown {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: var(--select-height);
    padding: var(--select-btn-p);
    background: transparent;
    border: var(--select-btn-border);
    border-radius: var(--border-radius-base);
    color: var(--select-btn-color);
    text-align: inherit;
    font-size: var(--select-btn-fz);
    line-height: var(--select-btn-lh);
    transition: var(--transition);
    overflow: hidden;
}

.formio-wrapper .select .fluid.selection.dropdown.is-invalid {
    border-color: var(--select-bth-invalid-border-color);
}

.formio-wrapper .select .fluid.selection.dropdown .choices__input {
    background-color: transparent;
    margin-left: calc(var(--margin-base) / 2);
}

.formio-wrapper .select .choices__list.choices__list--single .choices__item .choices__button {
    display: none;
}

.formio-wrapper .select .choices__list.choices__list--multiple {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.formio-wrapper .select .choices__list.choices__list--multiple .choices__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 calc(var(--margin-base) / 2) 0 0;
    padding: 4px 6px;
    border-radius: 4px;
    background-color: transparent;
    color: var(--select-btn-color);
    border: var(--select-btn-border);
}

.formio-wrapper .select .choices__list.choices__list--multiple .choices__item:not(:last-child) {
    margin-bottom: calc(var(--margin-base) / 2);
}

.formio-wrapper .select .choices__list.choices__list--multiple .choices__item .choices__button {
    width: 10px;
    height: 10px;
    margin: 0 0 0 5px;
    padding: 2px;
    border-left: none;
    mask: url('~ui-library/dist/assets/icons/close.svg') no-repeat center;
    mask-size: cover;
    background-color: var(--select-btn-color);
    background-image: none;
    background-size: contain;
}

.formio-wrapper .select .choices__list.choices__list--dropdown {
    --select-dd-border: var(--border-width-base) var(--border-style-base) var(--select-dropdown-border-color-theme, var(--border-color));
    --select-dd-mt: -1px;
    --select-dd-border-radius: 0 0 3px 3px;
    --select-dd-invalid-border-top-color: var(--select-dd-invalid-border-top-color-theme, var(--negative-default));
    --select-dd-hover-invalid-border-top-color: var(--select-dd-hover-invalid-border-top-color-theme, var(--negative-dark));
    --select-dd-mini-mt: 5px;
    --select-dd-bg: var(--select-dropdown-bg-theme, var(--bright));
    top: 100%;
    right: 0;
    background-color: var(--select-dd-bg);
    overflow: auto;
    width: 100%;
    height: fit-content;
    border: var(--select-dd-border);
    margin-top: var(--select-dd-mt);
    border-radius: var(--select-dd-border-radius);
    transition: var(--transition);
}

.formio-wrapper .select .choices__list.choices__list--dropdown .choices__item,
.formio-wrapper .select .choices__list.choices__list--dropdown .choices__item.is-highlighted {
    display: flex;
    padding: var(--select-option-wrapper-p);
    color: var(--select-option-wrapper-color);
    font-size: var(--select-option-fz);
    line-height: var(--select-option-lh);
    background-color: var(--select-dd-bg);
    white-space: normal;
}

.formio-wrapper .select .choices__list.choices__list--dropdown .choices__item:hover {
    background: var(--select-option-hover-bc);
    color: var(--select-option-hover-color);
}

/* Checkbox component */
.formio-wrapper .checkbox-wrapper {
    min-height: 30px;
    position: relative;
}

.formio-wrapper .checkbox-wrapper .form-check-label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.formio-wrapper .checkbox-wrapper input {
    position: absolute;
    left: -9999px;
}

.formio-wrapper .checkbox-wrapper input + span:before {
    display: inline-block;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    margin-right: calc(var(--margin-base) / 2);
    content: '';
    background-color: var(--checkbox-bg);
    border: var(--checkbox-border);
    border-radius: var(--border-radius-base);
    border-collapse: separate;
    transition: var(--transition);
}

.formio-wrapper .checkbox-wrapper input:checked + span:before {
    content: '';
    background-color: var(--checkbox-checked-background-color);
}

.formio-wrapper .checkbox-wrapper input:checked + span:after {
    position: absolute;
    left: calc(var(--checkbox-size) - var(--checkbox-inner-size));
    top: calc(var(--checkbox-size) - var(--checkbox-inner-size));
    display: table;
    width: var(--checkbox-inner-size);
    height: var(--checkbox-inner-size);
    border: 0;
    mask: url('~ui-library/dist/assets/icons/check.svg') no-repeat;
    mask-size: contain;
    background-color: var(--checkbox-checked-color);
    background-size: contain;
    transform: translate(-37%, -40%);
    opacity: 1;
    content: ' ';
    transition: var(--transition);
}

.formio-wrapper .checkbox-wrapper input + span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--checkbox-color);
    font-size: var(--checkbox-fz);
    line-height: var(--checkbox-size);
    position: relative;
    margin-right: 5px;
}

/* Tooltip component */
.formio-wrapper .tooltip {
    --tooltip-max-width: 300px;
    --tooltip-inner-box-shadow: none;
    --tooltip-inner-p: var(--margin-base);
    --tooltip-inner-color: var(--tooltip-inner-color-theme, var(--primary-default));
    --tooltip-inner-border: var(--border-width-base) var(--border-style-base) var(--tooltip-inner-border-theme, var(--border-color));
    --tooltip-table-inner-p: calc(var(--margin-base) - 9px) var(--margin-base);
    --tooltip-arrow-after-zi: 1061;
    --tooltip-arrow-after-border-color: var(--tooltip-arrow-after-border-color-theme, var(--bright));
    --tooltip-dark-inner-bc: var(--tooltip-dark-inner-bc-theme, var(--secondary-default));
    --tooltip-content-arrow-border-color: var(--tooltip-content-arrow-border-color-theme, var(--secondary-default));
    --tooltip-bg: var(--tooltip-bg-theme, var(--bright));
    max-width: var(--tooltip-max-width);
    font-family: var(--body-font-family);
    z-index: 11;
    font-size: 14px;
    width: 300px;
}

.formio-wrapper .tooltip-inner {
    padding: var(--tooltip-inner-p);
    box-shadow: var(--tooltip-inner-box-shadow);
    border: var(--tooltip-inner-border);
    color: var(--tooltip-inner-color);
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    border-radius: var(--border-radius-base);
    background-color: var(--tooltip-bg);
    font-weight: normal;
}
