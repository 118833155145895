.existing-interaction .Table thead th:nth-child(2),
.existing-interaction .Table tbody td:nth-child(2) {
    padding-left: calc(var(--margin-base) / 2);
}

.existing-interaction .Table {
    border-bottom: 1px solid var(--secondary-lighter);
}

.existing-interaction .Info-box {
    margin-top: var(--margin-base);
}

.existing-interaction .comment .Title {
    border-top: none;
}
