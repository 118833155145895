.Footer {
    --language-switcher-min-width: 150px;
    margin-top: auto;
}

.Footer .Footer__bottom > span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.Footer .Footer__bottom .LanguageSwitcher {
    margin-right: var(--padding-base);
    min-width: var(--language-switcher-min-width);
    flex-grow: 1;
}

.Footer .Footer__bottom .LanguageSwitcher + div {
    max-width: calc(100% - var(--language-switcher-min-width) - var(--padding-base));
}

@media (max-width: 767px) {
    .Footer .Footer__bottom .LanguageSwitcher span {
        text-align: left !important;

    }

    .Footer .Footer__bottom .LanguageSwitcher {
        min-width: 200px;
        margin-right: 0;
        margin-top: 10px;
        width: 100%;
    }

    .Footer .Footer__bottom > span {
        display: flex !important;
        flex-direction: column-reverse;
    }

    .Footer .Footer__bottom .LanguageSwitcher + div {
        max-width: 100%;
    }
}
