.research-details {
    margin-bottom: 40px !important;
}

.research-details .instrument.row {
    margin-bottom: 0;
}

.research-details p {
    margin-bottom: 0;
}

.research-details hr {
    display: block;
    height: 1px;
    margin: var(--margin-base) 0;
    border: none;
    color: var(--secondary-lighter);
    background-color: var(--border-color);
}

.research-details .research-details__image {
    width: 100%;
    max-width: 100%;
}

.research-details .research-details__image-wrapper {
    background-color: var(--secondary-light);
}

.research-details .research-details__image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    mask: url('../../../../../assets/images/news.svg') no-repeat 50% 50%;
    mask-size: 100px;
    background-color: var(--bright);
    background-size: contain;
    color: var(--bright);
    font-size: 0;
}

.research-details .research-details__image-text,
.research-details .research-details__help-text {
    margin-top: calc(var(--margin-base) / 2);
    color: var(--secondary-default);
    font-size: 1.2rem;
    line-height: 1.6rem;
}

.research-details .research-details__image-text {
    margin-bottom: var(--margin-base);
}

.research-details .research-details__content {
    white-space: pre-wrap;
    font-size: 1.6rem;
    line-height: 2.2rem;
}

@media (max-width: 768px) {
    .research-details .research-details__instrument .col-md-6 {
        margin-bottom: var(--margin-base);
    }
}

.research-details .research-details__instrument .Paragraph_highlights {
    margin-bottom: calc(var(--margin-base) / 2);
}

.research-details .research-details__instrument .keyTakeaways {
    white-space: pre-wrap;
    margin-bottom: calc(var(--margin-base) / 2);
}

.research-details .research-details__instrument ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    line-height: 2.4rem;
}

.research-details .research-details__instrument ul li {
    padding-left: 1em;
    text-indent: -1em;
}

.research-details .research-details__instrument ul li:before {
    content: '–';
    padding-right: 5px;
}

.research-details .research-details__instrument__image {
    max-height: 50px;
    width: auto;
    margin-bottom: var(--margin-base);
}

.research-details .research-details__instrument .overview-panel {
    display: block;
}

.research-details .research-details__instrument .overview-panel .overview-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.research-details .research-details__instrument .overview-panel .overview-row:first-child {
    margin-bottom: var(--margin-base);
}

.research-details .research-details__instrument .overview-panel .overview-item {
    margin-top: 0;
    margin-bottom: 0;
    width: 50%;
    min-width: 95px;
}

.research-details .research-details__instrument .overview-panel .overview-item__title,
.research-details .research-details__instrument .overview-panel .overview-item__value {
    font-size: 1.4rem;
    line-height: 2.4rem;
}

.research-details .research-details__instrument .overview-panel .overview-item_buy .overview-item__value {
    display: inline-block;
    padding: 0 8px;
    color: var(--bright);
    background-color: var(--positive-default);
    border-radius: 3px;
    font-weight: bold;
    line-height: 2.1rem;
}

.research-details .research-details__instrument .overview-panel .overview-item_sell .overview-item__value {
    display: inline-block;
    padding: 0 8px;
    color: var(--bright);
    background-color: var(--negative-default);
    border-radius: 3px;
    font-weight: bold;
    line-height: 2.1rem;
}

.research-details .research-details__instrument .research-details__instrument__btn {
    white-space: normal;
    word-wrap: break-word;
    height: auto;
}

.research-details .research-details__invest .Title {
    font-weight: normal;
}

.research-details .research-details__buttons {
    margin-top: var(--margin-base);
    display: flex;
}

.research-details .research-details__buttons a.button {
    line-height: 24px;
}

.research-details .research-details__buttons .button:not(:first-child) {
    margin-left: var(--margin-base);
}

@media (max-width: 767px) {
    .research-details .research-details__buttons {
        flex-direction: column;
    }

    .research-details .research-details__buttons .button:first-child {
        margin-bottom: var(--margin-base);
    }

    .research-details .research-details__buttons .button:not(:first-child) {
        margin-left: 0;
    }
}

/*
.research-details .Content-box .button {
    min-width: 60px;
    margin-left: var(--margin-base);
}

.research-details--image {
    max-width: 100%;
}

@media (max-width: 767px) {
    .research-details__image {
        margin: var(--margin-base) 0;
    }
}

.research-details__date {
    min-width: 100px;
    line-height: 1.7;
    margin-bottom: 10px;
}

.research-details__content {
    margin-bottom: 9px;
    white-space: pre-line;
}

@media (max-width: 767px) {
    .research-details__content {
        text-align: justify;
    }
}
*/
