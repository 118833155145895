.settings-page {
    margin: var(--padding-base) 0;
}

.settings-page .buttons button + button {
    margin-left: 20px;
}

.settings-page .buttons.row {
    margin-bottom: 0;
}
