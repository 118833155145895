.completion .stepper-content.completion-content {
    margin: var(--padding-base) auto 0;
    padding-bottom: calc(var(--padding-base) / 2 );
    max-width: 698px;
    width: 100%;
}

.completion .row {
    margin-bottom: 0;
}

.completion .completion-text {
    border-bottom: 1px solid var(--secondary-lighter);
    padding-bottom: calc(var(--padding-base) - 10px);
}

.completion .group-title {
    margin-top: calc(var(--margin-base) / 2);
}

.completion .group-title .Title {
    margin-bottom: calc(var(--margin-base) / 2);
}

.completion .bank-info > div:first-child {
    margin-bottom: var(--margin-base);
}

.completion .bank-info .Title {
    font-size: 1.4rem;
}

.completion .bank-info-description {
    color: var(--primary-default);
    font-size: 1.4rem;
    line-height: 1.57142857;
    margin-bottom: 0;
}

.completion .documents-info {
    margin-top: calc(var(--margin-base) / 2);
}

.completion .documents-title {
    border-top: 1px solid var(--secondary-lighter);
    padding-top: calc(var(--padding-base) - 10px);
}

.completion .documents-button-group button {
    display: block;
    margin: var(--margin-base) 0;
}

.completion .buttons-block-row {
    border-top: 1px solid var(--secondary-lighter);
}

.completion .buttons button {
    border-radius: 2px;
    margin-left: var(--margin-base);
}
