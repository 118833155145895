.news {
    --screen-md-min: 767px;
    margin-top: var(--padding-base);
}

.news.Content-box > .draggable {
    margin: calc(var(--padding-base) * -1);
    padding: var(--padding-base);
}

.news .LinkNews {
    position: relative;
    overflow: hidden;
    display: flex;
    height: 100%;
}

.news .news-item {
    padding: 20px 0 17px;
    margin: 0 !important;
}

.news .news-item:not(:last-child) {
    border-bottom: 1px solid var(--secondary-lighter);
}

.news .news-item .col-9 {
    padding-right: 0;
}

.news .news-item--image__container {
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
    padding-left: 0;
    padding-right: 10px;
}

.news .news-item--content__container {
    padding-right: 0;
}

@media (max-width: 768px) {
    .news .news-item--content__container,
    .news .news-item--image__container {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .news .news-item--content__container {
        padding-left: 20px;
    }
}

@media (min-width: 992px) {
    .news .news-item--content__container {
        padding-right: 0;
        padding-left: 10px;
    }
}

.news .news-item--image__container .LinkNews {
    max-height: 107px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.news .news-item--image {
    width: 100%;
    min-height: 100%;
    height: auto;
}

@media (max-width: 576px) {
    .news .news-item--image {
        position: relative;
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
}

.news .news-item--title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 11px;
}

.news .news-item--title h4 {
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 576px) {
    .news .news-item--title h4 {
        width: 100%;
    }
}

.news .news-item--date {
    min-width: 100px;
    text-align: right;
    line-height: 1.7;
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .news .news-item--date {
        text-align: right;
    }
}

.news .news-item--content {
    margin-bottom: 12px;
}

.news .news-item--link {
    display: inline-block;
    font-size: 14px;
    text-decoration: underline;
    margin-bottom: 0;
    line-height: 1;
}

.news .news-item--link a {
    color: var(--primary-light);
    line-height: 1.4;
}

.news .Pagination {
    width: calc(100% + 60px);
    margin: 17px calc(var(--padding-base) * -1) 0 calc(var(--padding-base) * -1);
    border-top: 1px solid var(--secondary-lighter);
    padding-top: var(--padding-base);
}

.news .Title {
    margin-bottom: 10px;
}

.news .load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: var(--padding-base);
}

.news .load-more--btn:hover,
.news .load-more--btn:focus,
.news .load-more--btn:active {
    background-color: transparent;
}
