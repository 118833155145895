.modify-portfolio {
    --indent-base: 15px;

    margin-top: calc(var(--indent-base) * 2);
}

.modify-portfolio h1 {
    margin-bottom: calc(var(--indent-base) * 2);
}

.modify-portfolio h1 + button {
    margin-top: -55px;
}

.modify-portfolio .table tr td {
    height: 65px;
    padding-top: 0;
    padding-bottom: 0;
}

.modify-portfolio .table tr th.column-fixed {
    text-align: center;
}

.modify-portfolio .table tr td.cell-fixed {
    background-color: transparent;
}

.modify-portfolio .content-wrapper {
    margin: 0 calc(var(--padding-base) * -1);
}

.modify-portfolio .buttons-block {
    margin-top: calc(var(--indent-base) * 2);
}

.modify-portfolio .buttons-block .row {
    margin-bottom: 0;
}

.modify-portfolio__header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    width: calc(100% + var(--padding-base) * 2);
    margin-left: calc(var(--padding-base) * -1);
    margin-right: calc(var(--padding-base) * -1);
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
    border-bottom: var(--border-width-base) solid var(--secondary-lighter);
}

@media only screen and (min-width: 576px) {
    .modify-portfolio__header {
        align-items: center;
        flex-direction: initial;
    }
}

.modify-portfolio__header h3 {
    margin: 0;
}

.modify-portfolio .Accordion {
    width: auto;
    margin: 0 calc(var(--indent-base) * 2);
}

.modify-portfolio .AccordionPanel-content-box {
    position: relative;
    bottom: -1px;
    padding-bottom: 0 !important;
}

.modify-portfolio .table-wrapper {
    margin-bottom: 0;
    padding: 0 var(--padding-base);
    border-bottom: 1px solid var(--secondary-lighter);
}

.modify-portfolio .table-wrapper tr td.with-link {
    padding: 25px 0;
}

.modify-portfolio .table-row:last-child .table-cell {
    border: 0;
}

.modify-portfolio .table-cell-header {
    padding-top: 0;
}

.modify-portfolio .ant-input {
    padding-right: var(--input-horizontal-padding) !important;
}

.modify-portfolio .form-group {
    margin-bottom: 0;
}

.modify-portfolio .Info-box {
    margin-top: var(--padding-base);
}

.modify-portfolio .content-wrapper .Info-box {
    margin: var(--padding-base) var(--padding-base) 0;
}
