.new-interaction .content-wrapper {
    max-width: 800px;
    margin: 0 auto;
}

.new-interaction .Content-box:first-child {
    padding-bottom: 0;
}

.new-interaction .interaction-content {
    border-bottom: 1px solid var(--secondary-lighter);
}

.new-interaction .buttons-block .row {
    margin-bottom: 0;
}
