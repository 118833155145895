.information .Content-box {
    padding-bottom: var(--indent-base);
}

.information .media {
    display: flex;
    align-items: center;
    padding-bottom: var(--margin-base);
    border-bottom: 1px solid var(--secondary-lighter);
}

.information .media-body {
    flex: 1;
}

.information .media-body h3 {
    margin-bottom: 0;
}

.information .media-body a {
    text-decoration: underline;
}

.information .media .picture {
    width: 72px;
    height: 72px;
    margin-right: 10px;
    border-radius: 50%;
}
