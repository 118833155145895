.Header__logo a {
    width: fit-content;
    display: inline-block;
}

.LogoWithClientName {
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
}

.LogoWithClientName .logo-title {
    margin-bottom: calc(var(--padding-base) / 16);
    transition: 0.3s;
}

.Header__logo a:hover .logo-title {
    color: var(--header-navigation-active);
}

.Header__logo a:not(.Navigation__link):hover {
    color: inherit;
}

.LogoWithClientName .logo-title strong {
    margin-left: 3px;
}

.client-name {
    width: fit-content;
    padding: 4px 6px;
    background-color: var(--secondary-default);
    color: var(--bright);
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    border-radius: var(--border-radius-base);
    border: none;
    cursor: pointer;
    transition: var(--transition);
}

.client-name:hover {
    background-color: var(--secondary-dark);
}
