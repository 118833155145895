.comment {
    display: block;
}

.comment .Title {
    padding-top: var(--padding-base);
    margin-bottom: calc(var(--margin-base) / 2);
    border-top: 1px solid var(--secondary-lighter);
}

.comment .Paragraph_primary {
    margin-bottom: var(--padding-base);
}

.comment .Textarea {
    color: var(--primary-default);
}
