@media (max-width: 768px) {
    .summary .overview-panel .overview-item:first-child {
        margin-bottom: var(--padding-base);
    }

    .summary .overview-panel .row {
        margin-bottom: 0;
    }
}

.summary .overview-panel {
    padding: var(--padding-base);
}

.summary .documents .Table thead > tr th:not(:first-child):last-child .table-cell__sorter .Icon {
    margin-left: 0.57142857em;
}

.summary .documents .Table thead > tr th:not(:first-child):last-child {
    right: 30px;
}

.summary .documents .AccordionPanel-content-box {
    padding-bottom: 0;
}

.summary .stepper-content {
    padding-bottom: var(--padding-base);
}
