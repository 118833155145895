.LanguageSwitcher {
    --language-switcher-height: 30px;
    --language-switcher-border-radius: 3px;
}

.LanguageSwitcher .select .select__dropdown__wrapper {
    bottom: 100%;
    top: unset;
}

.LanguageSwitcher .select .select__dropdown {
    border-radius: var(--language-switcher-border-radius) var(--language-switcher-border-radius) 0 0;
    background: var(--secondary-darker);
    border-color: var(--secondary-light);
    border-bottom: none;
}

.LanguageSwitcher .SelectButton {
    color: inherit;
    background: var(--secondary-darker) !important;
}

.LanguageSwitcher .select__option:hover {
    background-color: var(--secondary-light);
}

.LanguageSwitcher .checkbox-wrapper:hover {
    color: var(--secondary-darker) !important;
}

.LanguageSwitcher .form-group_opened .SelectButton {
    border-radius: 0 0 var(--language-switcher-border-radius) var(--language-switcher-border-radius);
}

.LanguageSwitcher .form-group_opened .select .anticon__arrow,
.LanguageSwitcher .select .anticon__arrow:hover:visited,
.LanguageSwitcher .select .anticon__arrow:visited {
    color: var(--secondary-light);
}

.LanguageSwitcher .checkbox-wrapper {
    color: var(--secondary-light);

}

.LanguageSwitcher .selected__values,
.LanguageSwitcher .checkbox-wrapper {
    font-size: var(--font-size-base);

}
