.preview-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    box-shadow: none;
    max-height: 985px;
}

.preview-content__inner {
    overflow: hidden;
}

.preview-content__inner .Title {
    font-weight: 500;
}

.preview-content-attachments {
    display: block;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1;
}

.preview-content-attachments .preview-attachments {
    display: inline-block;
    text-decoration: underline;
    color: var(--primary-light);
}

.preview-content-attachments a {
    color: var(--primary-light);
    line-height: 1;
}

.preview-content .preview-metadata-from {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.preview-content .preview-date {
    width: 150px;
}

.preview-content .preview-content-metadata,
.preview-content .preview-content-attachments {
    padding: var(--indent-base) 0 0;
    margin: 0;
    border-top: 1px solid var(--secondary-lighter);
}

.preview-content table td:first-child {
    max-width: 80px;
}

.preview-content table td:last-child {
    max-width: 86px;
}

.preview-content table td:nth-child(2) {
    max-width: 140px;
}

.preview-content .Paragraph_primary {
    border-top: 1px solid var(--secondary-lighter);
    padding: var(--padding-base) 0 0;
    margin-top: var(--indent-base);
    margin-bottom: 0;
    white-space: pre-wrap;
    max-height: 100%;
    overflow-y: auto;
}

.preview-buttons {
    margin-top: var(--padding-base);
    padding: var(--padding-base) 0 0;
    border-top: 1px solid var(--secondary-lighter);
}

.preview-buttons .button-group {
    display: flex;
    padding: 0;
    justify-content: space-between;
}
